import React from 'react';
import './Header.css';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import {
    ButtonSpacer,
    PopoverMenu,
    PrimaryAddButton,
    PrimaryButton,
    PrimaryExportButton,
    SecondaryButton
} from './Components';
import {ListItemIcon, ListItemText} from "@mui/material";
import {Check} from "@mui/icons-material";

export function Header(props) {
    const back = props.haveBack ?
        (<PrimaryButton onClick={props.onBack} >Zpět</PrimaryButton>) : '';
    let pageComands = '';
    if(props.currentPage.template === 'home') {
        pageComands = (<span className='page-commands'>
            <PrimaryAddButton onClick={props.onImport} >Import ze souboru</PrimaryAddButton>
            <ButtonSpacer />
            <PrimaryAddButton onClick={props.onNewClient} >Nový klient</PrimaryAddButton>
            <ButtonSpacer />
            <PrimaryAddButton onClick={props.onNewTemplate} >Nový formulář</PrimaryAddButton>
            <ButtonSpacer />
            <PrimaryAddButton onClick={props.onNewTask} >Nový úkol</PrimaryAddButton>
            </span>);
    }else if(props.currentPage.template==='client_form') {
        if(props.currentPage.record.id) {
            pageComands = (<span className='page-commands'>
                <PrimaryAddButton onClick={props.onNewDocument}>Nový dokument</PrimaryAddButton>
                <ButtonSpacer />
                <PrimaryAddButton onClick={props.onNewClientNote}>Nová poznámka</PrimaryAddButton>
                <ButtonSpacer />
                <PrimaryAddButton onClick={props.onNewClientAppointment}>Nová schůzka</PrimaryAddButton>
                <ButtonSpacer />
                <PrimaryExportButton onClick={props.onClientExport}>Export do XLS</PrimaryExportButton>
                </span>);
        }
    }
    let userButton = props.currentPage.template==='user_settings' ?
        <SecondaryButton onClick={props.onLogout}>Odhlásit</SecondaryButton> :
        <SecondaryButton onClick={props.onSettings}>{props.login.name} {props.login.surname}</SecondaryButton>;
    const onedriveIcon = props.login.onedrive_logged ? <ListItemIcon><Check /></ListItemIcon> : '';
    return (
        <div className={'header'}>
            <div>
            <div className="header-left">
                {back}
            </div>
            <div className="header-right">
                {pageComands}
                <span className="user"><PopoverMenu title={props.login.name +' '+props.login.surname}>
                    <MenuItem onClick={props.onSettings}>Nastavení</MenuItem>
                    <MenuItem onClick={props.onOnedrive} disabled={!!onedriveIcon || !props.login.onedrive_login}>
                        {onedriveIcon}
                        <ListItemText inset={!onedriveIcon} primary="OneDrive" secondary={props.login.onedrive_user ? props.login.onedrive_user.userPrincipalName : null}/>

                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={props.onLogout}>Odhlásit</MenuItem>
                </PopoverMenu></span>
            </div>
                <div className="clearfix"/>
            </div>
        </div>
    );
}

export default Header;