import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {deliveryPointProcessed} from "./Functions";

function TabPanel(props) {
    const { content, value, index, ...other } = props;

    /*Render hidden tabs as display none?*/
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 1 }}>
                    {content}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    content: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabbedPane(props) {
    let [value, setValue] = React.useState(props.currentTab || 0);
    if(props.currentTab!==undefined && props.setCurrentTab!==undefined) {
        value = props.currentTab;
        setValue = props.setCurrentTab;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let filteredTabs = [];
    if(props.tabs) {
        for (const tab of props.tabs) {
            if(!tab.deleted) {
                filteredTabs.push(tab);
            }
        }
    }
    const headers = filteredTabs.map((tab, index) => <Tab key={index} label={tab.title} className={tab.headerClass} {...a11yProps(index)} />);
    const actions = props.actions ? props.actions.map((action, index) => <Tab key={'actions_'+index} onClick={action.action} icon={action.startIcon} label={action.title} content={action.title} />) : [];
    const tabs = filteredTabs.map((tab, index) => <TabPanel key={'tabs_'+index} index={index} value={value} content={tab.content} />);
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} variant="scrollable" onChange={handleChange} aria-label="basic tabs example">
                    {headers}{actions}
                </Tabs>
            </Box>
            {tabs}
        </Box>
    );
}
