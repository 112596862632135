import React from "react";
import './Progress.css';

class Progress extends React.Component {

    render() {
        const pDivs = Object.keys(this.props.progresses).map((id) =>
            <div className="progress" key={id}>{this.props.progresses[id].title}</div>
        );
        if(pDivs.length===0) {
            return [];
        }
        let isModal = false;
        for(const [, v] of Object.entries(this.props.progresses)) {
            if(!v.background) {
                isModal = true;
                break;
            }
        }
        if(isModal) {
            return <div className="progress-modal">
                <div className="spinner"/>
                <div className="progresses">
                    <div className="progresses-background">{pDivs}</div>
                </div>
            </div>;
        }else {
            return <div className="background-progresses">{pDivs}</div>;
        }
    }
}

export default Progress;