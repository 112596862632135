import {excelTimeToDate} from "./Functions";
import * as XLSX from "xlsx";
import {KGasLevelOptions, KVoltageLevelOptions} from "./RecordFields";

const AmaxXlsColummns = [
    "Datum schůzky",
    "Datum sjednání",
    "Obchodní zástupce",
    "IČO",
    "Osoba",
    "Funkce",
    "Firma",
    "Telefon",
    "Místo schůzky",
    "Adresa",
    "Obec",
    "Okres",
    "PSČ",
    "Poznámka",
    "Původní id",
    "Příznak",
    "Poznámka zákazníka"
    ];

const MistaSpotrebyColumns = [
    "Komodita", //"Typ odběrného místa",
    "Vlastní název",
    "EAN/EIC",
    "MS",
    "Napěťová úroveň",
    "Typ měření",
    "Adresa",
    "Variabilní symbol",
    "IČ",
    "Název společnosti"
];

function findEnumValue(value, enumValues) {
    let parts = value.match(/\b(\w+)\b/g);
    for(const p of parts) {
        if(enumValues.indexOf(p)>=0) {
            return p;
        }
    }
    return value;
}

function mistaSpotrebyToClientCompany(row) {
    let addressParts = row["Adresa"].split(",");
    let psc = addressParts[addressParts.length - 1].trim();
    let city = (addressParts[addressParts.length - 2] || '').trim();
    let street = addressParts.slice(0, -2).join(',').trim();
    let client_company = {
        company: row["Název společnosti"],
        type: 'company',
        ico: row['IČ'],
        delivery_point: {
            records: [{
                name: row["Vlastní název"],
                delivery_point_type: row["Komodita"] === 'P' ? 'gas' : 'electricity',
                ean: row["EAN/EIC"],
                ms: row["MS"],
                voltage_level: row["Komodita"] === 'P' ? findEnumValue(row["Napěťová úroveň"], KVoltageLevelOptions) : '',
                voltage_level_gas: row["Komodita"] !== 'P' ? findEnumValue(row["Napěťová úroveň"], KGasLevelOptions) : '',
                measurement_type: row['Typ měření'],
                address: {
                    street: street,
                    city: city,
                    postal: psc
                },
                variable_number: row["Variabilní symbol"],
                supplier: 'EON'
            }]
        }
    }

    return client_company;
}

function amaxToClient(row){
    const nameSplit = row.Osoba.split(' ');
    let client = {
        name: nameSplit.slice(0, -1).join(' '),
        surname: nameSplit[nameSplit.length - 1],
        phone: row.Telefon,
        client_company: {
            records: [{
                type: row['IČO'] ? 'company' : 'person',
                company: row.Firma,
                name: nameSplit.slice(0, -1).join(' '),
                surname: nameSplit[nameSplit.length - 1],
                address: {
                    street: row.Adresa,
                    city: row.Obec,
                    postal: row['PSČ']
                },
                ico: row['IČO']
            }],
        },
        client_appointment: {
            records: [{
                date: excelTimeToDate(row['Datum schůzky']).toISOString(),
                created: excelTimeToDate(row['Datum sjednání']).toISOString(),
                description: row['Poznámka'],
                note: row['Poznámka zákazníka'],
                appointee: row['Místo schůzky'],
                is_imported: true,
                address: {
                    street: row.Adresa,
                    city: row.Obec,
                    postal: row['PSČ']
                },
            }]
        }
    };
    return client;
}

const ImportFormats = [
    {
        title: 'Amax callcentrum',
        importColumns: AmaxXlsColummns,
        convert: amaxToClient,
        record: 'client'
    },
    {
        title: 'EON Místa spotřeby',
        importColumns: MistaSpotrebyColumns,
        convert: mistaSpotrebyToClientCompany,
        record: 'client_company'
    }
];

function autodetectFormat(readData) {
    if(Array.isArray(readData)) {
        readData = readData[0];
    }
    let autodetectResult = [];
    ImportFormats.forEach((format) => {
        let matchedColumns = 0;
        format.importColumns.forEach((column) => {
            if(readData[column]!==undefined) {
                matchedColumns++;
            }
        });
        let missingColumns = format.importColumns.length-matchedColumns;
        let unknownColumns = Object.keys(readData).length-matchedColumns;
        autodetectResult.push({
            matchedColumns: matchedColumns,
            missingColumns: missingColumns,
            unknownColumns: unknownColumns,
            format: format,
            confidence: matchedColumns / (missingColumns + matchedColumns + unknownColumns)
        });
    });
    autodetectResult.sort((a, b) => {
        return b.confidence - a.confidence;
    });
    return autodetectResult;
}

function convertData(data, format) {
    if(!Array.isArray(data)) {
        data = [data];
    }
    let parsedRecords = [];
    data.forEach((row)=>{
        const client = format.convert(row);
        parsedRecords.push(client);
    });
    return parsedRecords;
}

function processAsXLS(fResult) {
    var workbook = XLSX.read(fResult);
    if (!workbook.opts) {
        return false;
    }
    console.log(workbook);
    const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {defval: null});
    const format = autodetectFormat(json);
    return {
        data: json,
        format: format
    };
}

export function processData(file) {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = function () {
            const arrayBuffer = fileReader.result;
            const r = processAsXLS(arrayBuffer);
            if (!r) {
                //TODO try another format other than XLS
                reject('Nepodařilo se načíst XLS soubor');
            }else if(r.format[0].confidence!==1) {
                resolve({
                    status: 'Nejistá automatická detekce formátu',
                    convert: (format) => convertData(r.data, format),
                    format: r.format
                });
            }else {
                resolve({
                    status: 'converted',
                    clients: convertData(r.data, r.format[0].format),
                    format: r.format[0]
                });
            }
        }
        fileReader.readAsArrayBuffer(file);
    });
}