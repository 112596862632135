import React from 'react';

class LoginForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {username: '', password: ''};
    }
    user
    render() {
        return <form onSubmit={(ev) => {
                ev.preventDefault();
                this.props.onSubmit(this.state.username, this.state.password);
                this.setState({password: ''});
            }}>
            <label>Jméno: <input type="text" value={this.state.username}
                                 onChange={(ev) => this.setState({username: ev.target.value})}/></label>
            <br/>
            <label>Heslo: <input type="password" value={this.state.password}
                                 onChange={(ev) => this.setState({password: ev.target.value})}/></label>
            <br/>
            <span className="login-error">{this.props.lastError}</span>
            <br/>
            <input type="submit" value="Přihlásit" disabled={!this.state.username}/>
        </form>;
    }
}

export default LoginForm;
