import React from 'react';
import {OwnCompanyForm, SettingsForm} from "./Forms";

export function UserSettings(props) {
    return <div>
        <h1>Nastavení uživatele</h1>
        <SettingsForm data={props.login} readOnly="true" onFormSave={props.onUserSave}/>
        <h1>Nastavení společnosti</h1>
        <OwnCompanyForm data={props.company || {}} readOnly="true" onFormSave={props.onCompanySave}/>
    </div>;
}