
export class Office {

    constructor(token) {
        this.token = token;
    }

    isLogged() {
        return !!this.token;
    }

    sendEmail(params) {
        let errors = {};
        //recipients, subject, body, attachments
        let recipients = [];
        if(!params.recipients) {
            errors.recipients = 'Není vyplněn příjemce. Lze zadat více adres oddělených ;';
        }else {
            recipients = params.recipients.split(';').map((val) => val.trim());
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            for(const address of recipients) {
                if(!address.match(validRegex)) {
                    errors.recipients = 'Adresa: '+address+' není platná';
                    break;
                }
            }
        }
        if(!params.subject) {
            errors.subject = 'Není vyplněný předmět';
        }
        if(!params.body) {
            errors.body = 'Není vyplněno tělo';
        }
        if(Object.keys(errors).length>0) {
            return Promise.reject(errors);
        }

        let sendPromises = [];
        try {
            for (const recipient of recipients) {
                let message = {
                    "subject": params.subject,
                    "body": {
                        "contentType": "Text",
                        "content": params.body
                    },
                    "toRecipients": [{
                        "emailAddress": {
                            "address": recipient
                        }
                    }]
                };
                let attachmentPromises = [];
                if (params.attachments) {
                    message.attachments = [];
                    for (const attachment of params.attachments) {
                        //attachment is blob
                        attachmentPromises.push(this.blobToBase64(attachment.blob).then(base64 => {
                            message.attachments.push({
                                "@odata.type": "#microsoft.graph.fileAttachment",
                                "name": attachment.name,
                                "contentType": "application/vnd.ms-excel",
                                "contentBytes": base64
                            });
                        }));
                    }
                }
                sendPromises.push(Promise.all(attachmentPromises).then( () => {
                    const fetchParams = {
                        cache: 'no-cache',
                        headers: {
                            "Authorization": "Bearer " + this.token,
                            "Content-Type": "application/json"
                            //Host, SDK_VERSION?
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            message: message,
                            saveToSentItems: "true"
                        })
                    };
                    return fetch("https://graph.microsoft.com/v1.0/me/sendMail", fetchParams);
                }));
            }
        }catch(err){
            errors.send = err.message || err.toString();
            return Promise.reject(errors);
        }
        return new Promise((resolve, reject) => {
            Promise.all(sendPromises).then((r) => {
                if(!r[0].ok) {
                    //error
                    errors.send = r[0].status.toString() + ' - ' + r[0].statusText;
                    reject(errors);
                }else {
                    resolve(r);
                }
            }, (err) => {
                errors.send = err.message || err.toString();
                reject(errors);
            });
        });
    }
    async asyncBlobToBase64(blob) {
        const base64 = await this.blobToBase64(blob);
        return base64;
    }

    blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.substring(reader.result.indexOf(',')+1));
            reader.readAsDataURL(blob);
        });
    }
}