import React from "react";
import BasicTabbedPane from "./TabbedPane";
import {OffersList} from "./RecordTable";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {OfferSettingsForm} from "./Forms";
import {PrimaryButton} from "./Components";

class OfferSettings extends React.Component {
    render() {
        const office = this.props.officeLogged ?
            <PrimaryButton onClick={this.props.onOfficeLogout} >Odhlásit</PrimaryButton> :
            <PrimaryButton onClick={() => window.location.href = this.props.officeLogin} >Přihlásit</PrimaryButton>
        let sender;
        if(this.props.officeLogged) {
            sender = <p>{this.props.officeUser.displayName} &lt;{this.props.officeUser.userPrincipalName}&gt;</p>
        }
        return <div>
            <h1>Zpráva</h1>
            <OfferSettingsForm {...this.props} data={this.props.offerEmail} readOnly="true" />
            <h1>Odesílatel</h1>
            {office}
            {sender}
        </div>
    }
}

export class Offers extends React.Component {

    render() {
        const title = "Cenové nabídky";
        if(this.props.onPageRender) {
            this.props.onPageRender({
                title: title
            });
        }
        const offersActions = [<Button key={1} onClick={this.props.onNewOfferRecipient} startIcon={<AddIcon/>}>Nový partner</Button>];
        let tabs = [{title: 'Nastavení emailu', content: <OfferSettings {...this.props} onFormSave={this.props.onOfferSettingSave}/>},
            {title: 'Příjemci', content: <OffersList {...this.props} records={this.props.offerRecipients} actions={offersActions} contextActions={[
                    {onClick: (records) => this.deleteRecords(records, 'offer_recipient'), title: 'Smazat označené'} ]}/>
            }
        ];
        return (
            <div>
                <BasicTabbedPane tabs={tabs}/>
            </div>
        )
    }
}
