import React, {useRef} from 'react';
import {GRID_DETAIL_PANEL_TOGGLE_FIELD, GridActionsCellItem} from "@mui/x-data-grid-pro";
import './Forms.css';
import {CloseButton, EditButton, SecondaryButton} from "./Components";
import {
    Fab,
    TextField,
    Input,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {formatBytes, formatDate, formatDateTime} from "./Functions";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import {
    KAddressFields,
    KAppointmentFields,
    KClientContactFields,
    KCompanyFields,
    KDeliveryPointFields,
    KDocumentFields,
    KNoteFields, KOfferRecipient, KOfferSettings, KOwnCompanyFields, KTaskFields,
    KTemplateFields, KYearConsumptionFields
} from "./RecordFields";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import DateTimePicker from '@mui/lab/DateTimePicker';
import {cs} from 'date-fns/locale';
import AresApp from "./ares";
import _ from "lodash";

function FileInput(props) {
    const clearButton = !props.value.filename ? '' : <Button style={{float: 'right'}} variant="outlined" startIcon={<DeleteIcon/>} onClick={(e) => {
        props.onChange({
            filename: '',
            size: 0,
            data: '',
            modified: false
        });
        fileInputRef.current.value = '';
    }} />;

    const fileInputRef = useRef(null);
    const details = !props.value.filename ? '' : <span>
            <span> {props.value.filename} </span>,
            <span>[{formatBytes(props.value.size)}]</span>,
            <br/>,
            <span>Změněno: {formatDateTime(props.value.modified)}</span>
    </span>;
    return (<fieldset>
            <legend>{props.label}</legend>
            <label>
                <Button variant="outlined" component="span">Vybrat soubor</Button>
                {details}
                <input type="file" hidden ref={fileInputRef} onChange={(e) => {
                    if(e.currentTarget.files.length===0) {
                        if(props.clearOnCancel) {
                            props.onChange({
                                filename: '',
                                size: 0,
                                data: false,
                                modified: false
                            });
                        }
                    }else {
                        let fileReader = new FileReader();
                        let file = e.currentTarget.files[0];
                        fileReader.onload = function () {
                            props.onChange({
                                filename: file.name,
                                size: file.size,
                                modified: file.lastModified,
                                data: fileReader.result
                            });
                        };
                        fileReader.readAsArrayBuffer(e.currentTarget.files[0]);
                    }
                }} />
            </label>
            {clearButton}
        </fieldset>
    );
}

function isValueSet(item, value){
    if(!value){
        return false;
    }
    if(item.type==='file'){
        if(!value.filename){
            return false;
        }
    }
    return true
}

function validateItem(item, value, record) {
    if(item.required && !isValueSet(item, value)) {
        if(item.default) {
            let possibleDefault = item.default({row: record});
            if(possibleDefault) {
                return true;
            }
        }
        return false;
    }
    if(item.validator && item.validator(value, {row: record})!==true) {
        return false;
    }
    return true;
}

function propsValue(props) {
    let val = props.item.valueFormatter ? props.item.valueFormatter({
        id: props.api.row.id,
        value: props.value,
        field: props.item.field,
        api: {
            getRow: (id) => props.api.row
        }
    }) : props.value;
    return val;
}

function FormItem(props){
    const visible = !props.item.condition || props.item.condition({
        row: props.api.row,
        value: props.value
    });
    if(props.item.type==='actions' || props.item.field===GRID_DETAIL_PANEL_TOGGLE_FIELD || !visible) {
        return '';
    } else if(props.item.type==='address') {
        return ( <AddressForm {...props} data={props.value} naked={true} name={props.item.field} readOnly={props.readOnly} onFormChange={(val) => props.onItemChange(props.item.field, val)}/> );
    }
    const disabled = (props.readOnly || props.item.readOnly);
    if(disabled) {
        let val = propsValue(props);
        if(props.item.type==='boolean') {
            return <TextField disabled label={props.item.headerName || props.item.field}
                              value={val == 1 ? 'Ano' : 'Ne'}/>
        }else if(props.item.type==='singleSelect' && !Array.isArray(props.item.valueOptions)) {
            val = props.item.valueOptions[val] || val;
        }
        return <TextField disabled label={props.item.headerName || props.item.field} value={val}/>
    }
    //validate
    let placeholder = props.item.default ? props.item.default({row:props.api.row}) : '';
    let error = null;
    if(props.item.required && !props.value && !placeholder) {
        error = props.item.required===true ? 'Toto pole je povinné' : props.item.required;
    }else if(props.item.validator) {
        let validation = props.item.validator(props.value, {row: props.api.row});
        if(validation===true) {
            //ok
        }else if(typeof validation === "string") {
            error = validation;
        }else {
            error = 'Toto pole je chybně vyplněno';
        }
    }
    if(props.item.type==='textarea') {
        return <TextField fullWidth width="" multiline={true} maxRows="10" label={props.item.headerName} value={props.value} onChange={(e) => props.onItemChange(props.item.field, e.currentTarget.value)}/>
    }else if(props.item.type==='file') {
        return <FileInput label={props.item.headerName} value={props.value} onChange={(e) => props.onItemChange(props.item.field, e)}/>
    }else if(props.item.type==='singleSelect') {
        let items;
        if(Array.isArray(props.item.valueOptions)) {
            items = props.item.valueOptions.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>);
        }else {
            items = [];
            for(const k in props.item.valueOptions) {
                items.push(<MenuItem key={k} value={k}>{props.item.valueOptions[k]}</MenuItem>);
            }
        }
        return <FormControl sx={{minWidth: 210}}>
            <InputLabel>{props.item.headerName}</InputLabel>
                <Select disabled={disabled} label={props.item.headerName} value={props.value || placeholder} onChange={(e) => props.onItemChange(props.item.field, e.target.value)}>
            {items}
        </Select>
        </FormControl>
    }else if(props.item.type==='boolean') {
        return <FormControl sx={{minWidth: 210}}>
            <InputLabel>{props.item.headerName}</InputLabel>
            <Select label={props.item.headerName} value={props.value == 1 ? '1' : '0'} onChange={(e) => props.onItemChange(props.item.field, e.target.value)}>
                <MenuItem value="0">Ne</MenuItem>
                <MenuItem value="1">Ano</MenuItem>
            </Select>
        </FormControl>
    }else if(props.item.type==='dateTime') {
        return <LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
            <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label={props.item.headerName}
                value={props.value}
                onChange={(e) => props.onItemChange(props.item.field, e)}
            />
        </LocalizationProvider>
    }else if(props.item.type==='date') {
        return <LocalizationProvider dateAdapter={AdapterDateFns} locale={cs}>
            <DatePicker
                renderInput={(props) => <TextField {...props} />}
                label={props.item.headerName}
                value={props.value}
                onChange={(e) => props.onItemChange(props.item.field, e)}
            />
        </LocalizationProvider>
    }else if(props.item.type==='ico') {
        return <TextField label={props.item.headerName || props.item.field}
                          InputProps={{
                              endAdornment: (
                              <InputAdornment position="end">
                              <IconButton disabled={!props.value} edge="end" onClick={(ev) => {
                                  AresApp.getIco(props.value).then((info) =>
                                  {
                                      if(!info) {
                                          return;
                                      }
                                      //load fields from response
                                      props.api.updateFields({
                                          company: info.obchodniJmeno,
                                          ico: info.ico,
                                          dic: info.dic,
                                          address: {
                                              city: info.sidlo.nazevObce,
                                              street: info.sidlo.nazevUlice +' '+info.sidlo.cisloDomovni+'/'+info.sidlo.cisloOrientacni,
                                              postal: info.sidlo.psc
                                          }
                                      });
                                  })
                              }
                              }><RefreshIcon/></IconButton>
                              </InputAdornment>)
                          }}
                          value={props.value}
                          error={!!error}
                          helperText={error}
                          placeholder={placeholder}
                          required={!!props.item.required}
                          onChange={(e) => props.onItemChange(props.item.field, e.currentTarget.value)}/>

    }else if(props.item.type==='year_consumption') {
        let val = propsValue(props);
        return <TextField label={props.item.headerName || props.item.field}
                          disabled={true}
                          InputProps={{
                              endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={(ev) => {
                                    props.onRecordDetail?.(props.value, 'year_consumption', 'Roční spotřeba', undefined, (updatedRecord) => {
                                        return props.onItemChange(props.item.field, updatedRecord);
                                    });
                                }}>
                                    <ReadMoreIcon/>
                                </IconButton>
                              </InputAdornment>)
                          }}
                          value={val}
                          error={!!error}
                          helperText={error}
                          placeholder={placeholder}
                          required={!!props.item.required}
                          />
    }
    /*return (
        <div className="form-item">
            <label><span className="label">{props.item.title}</span>
                <input type={props.item.type} value={props.value} onChange={(e) => props.onItemChange(props.item.name, e.currentTarget.value)}/>
            </label>
        </div>
    );*/
    return <TextField label={props.item.headerName || props.item.field}
                      type={props.item.type==='numeric' ? "number" : 'text'}
                      value={props.value}
                      error={!!error}
                      helperText={error}
                      placeholder={placeholder}
                      required={!!props.item.required}
                      onChange={(e) => props.onItemChange(props.item.field, e.currentTarget.value)}/>
}

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readOnly: typeof props.readOnly==="undefined" ? !!props.data.id : props.readOnly,
            data: props.data || {}
        };
        this.itemChanged = this.itemChanged.bind(this);
        this.isReadOnly = this.isReadOnly.bind(this);
        this.isEditOnly = this.isEditOnly.bind(this);
        this.reset = this.reset.bind(this);
        this.isValid = this.isValid.bind(this);
        this.save = this.save.bind(this);
        this.renderItem = this.renderItem.bind(this);
    }

    itemChanged(itemName, newVal, data) {
        let val = _.cloneDeep(this.state.data); // JSON.parse(JSON.stringify(this.state.data));
        let tmp = val;
        let parts = itemName.split('.');
        while(parts.length>1) {
            let part = parts.shift();
            if(tmp[part]===undefined) {
                tmp[part] = {};
            }
            tmp = tmp[part];
        }
        if(data) {
            newVal = {
                filename: newVal,
                data: data
            };
        }
        tmp[parts[0]] = newVal;
        if(!this.props.naked) {
            this.setState({data: val});
        }
        if(this.props.onFormChange) {
            this.props.onFormChange(val);
        }
    }

    isReadOnly() {
        return this.props.naked ? this.props.readOnly : this.state.readOnly;
    }

    isEditOnly() {
        return !this.state.data.id;
    }

    isChanged() {
        if(!this.state.data) {
            return false;
        }
        let changed = false;
        this.props.items.forEach(function (item) {
            if(item.field==='actions' || item.field===GRID_DETAIL_PANEL_TOGGLE_FIELD || item.readOnly) {
                return;
            }
            if(item.field==='address') {
                KAddressFields.forEach((subItem) =>
                    changed |= (this.state.data.address||{})[subItem.field] !== (this.props.data.address||{})[subItem.field]);
            }else {
                changed |= this.state.data[item.field] !== this.props.data[item.field];
            }
        }, this);
        return changed;
    }

    isValid() {
        if(!this.state.data) {
            return true;
        }
        let valid = true;
        this.props.items.forEach(function (item) {
            valid &= validateItem(item, this.state.data[item.field], this.state.data);
        }, this);
        return valid;
    }

    getValue(name) {
        let data = this.props.naked ? this.props.data : this.state.data;
        let parts = name.split('.');
        while(parts.length>0 && data!==undefined) {
            let part = parts.shift();
            data = data[part];
        }
        return data || '';
    }

    reset() {
        this.setState({data: this.props.data});
    }

    save() {
        //extract only data in the form for save
        let changes = {};
        let changedData = {...this.props.data};
        if(!this.isValid()) {
            return false;
        }
        this.props.items.forEach(function (item) {
            let newVal = this.state.data[item.field];
            if(typeof newVal === "string") {
                newVal = newVal.trim();
            }
            if(newVal!==changedData[item.field]
                && !item.readOnly) {
                changedData[item.field] = newVal;
                changes[item.field] = newVal;
            }else if(item.default) {
                //Item have default value
                let oldDefault = item.default({row: this.props.data});
                if(!newVal
                    //Uncomment if change default value if already set and is default
                    // || this.props.data[item.field]===oldDefault
                ) {
                    //set new default if not empty
                    let newDefault = item.default({row: this.state.data});
                    if(newDefault && newDefault!==newVal) {
                        changedData[item.field] = newDefault;
                        changes[item.field] = newDefault;
                    }
                }
            }
        }, this);
        return this.props.onFormSave(changedData, changes)
            .then(()=>this.setState({
                readOnly: true,
                data: changedData
            }));
    }

    renderItem(item, k) {
        if(item.form !== undefined && !item.form) {
            return [];
        }
        if(this.isReadOnly() && item.editOnly) {
            return [];
        }
        const api = {
            form: this,
            row: this.state.data,
            updateFields: (values) => {
                this.props.items.forEach((item) => {
                    if(values[item.field]) {
                        this.itemChanged(item.field, values[item.field]);
                    }
                });
            }
        };
        if(item.type==='address') {
            const items = [];
            KAddressFields.forEach((subItem, j) =>
                items.push(<FormItem inWindow={this.props.asWindow}
                                     item={subItem}
                                     value={this.getValue(item.field+'.'+subItem.field) || ''}
                                     readOnly={this.isReadOnly()}
                                     key={k+'-'+j}
                                     api={api}
                                     onItemChange={(itemName, newVal, data) => api.form.itemChanged(item.field+'.'+itemName, newVal, data)}
                />)
            );
            return items;
        }else {
            return [<FormItem inWindow={this.props.asWindow}
                             item={item}
                             value={this.getValue(item.field) || ''}
                             readOnly={this.isReadOnly()}
                             key={k}
                             api={api}
                             onRecordDetail={this.props.onRecordDetail}
                             onItemChange={(itemName, newVal, data) => item.onChange ? item.onChange(api, newVal, data) : this.itemChanged(itemName, newVal, data)}/>]
        }
    }

    render() {
        const formItems = [];
        const thiz = this;
        this.props.items.forEach((item, k) => formItems.push(...thiz.renderItem(item, k)));
        if(this.props.naked) {
            return <div className="form-naked">{formItems}</div>;
        }
        //const editButton = this.state.readOnly ? <EditButton onClick={() => this.setState({readOnly: false})} /> : '';
        const editButton = this.state.readOnly ? (<Fab color="secondary" aria-label="edit" onClick={() => this.setState({readOnly: false})}>
            <EditIcon />
        </Fab>) : '';
        const saveButton = !this.state.readOnly && this.props.onFormSave ? <Fab color="secondary" aria-label="save" disabled={!this.isChanged() || !this.isValid()} onClick={() => this.save()}><SaveIcon /></Fab> : '';
        const resetButton = !this.state.readOnly && !this.isEditOnly() ? <Fab color="secondary" onClick={() => {
            this.reset();
            this.setState({readOnly: true});
        }}><CancelIcon /></Fab> : '';
        const deleteButton = this.state.readOnly && this.props.onFormDelete ? (<Fab color="secondary" aria-label="delete" onClick={() => this.props.onFormDelete()}>
            <DeleteIcon />
        </Fab>) : '';
        const moveButton = this.state.readOnly && this.props.onFormMove ? (<Fab color="secondary" aria-label="move" onClick={() => this.props.onFormMove()}>
            <DriveFileMoveIcon />
        </Fab>) : '';
        if(this.props.asWindow) {
            const closeButton = this.props.onWindowClose ? <span className="right"><CloseButton onClick={() => this.props.onWindowClose()} /></span> : '';
            const title = this.props.title ? this.props.title : (this.getValue('name') + ' ' + this.getValue('surname'))
            return ([
                    <div key="header" className="modal-header">{title}{closeButton}</div>,
                    <div key="body" className="modal-body">
                        <Box sx={{
                            '& .MuiTextField-root': { m: 1, width: '30ch', maxWidth: '100%' },
                        }}>{formItems}</Box>
                        <div className="clearfix"/></div>,
                    <div key="footer" className="modal-footer">{editButton}{saveButton}{resetButton}{moveButton}{deleteButton}{this.props.buttons}</div>
                ]);
        } else {
            return (
                <div className="form">
                    <div className="form-header"><Stack spacing="2">{editButton}{saveButton}{resetButton}{moveButton}{deleteButton}{this.props.buttons}</Stack></div>
                    <div className="clearfix"/>
                    <Box sx={{
                        '& .MuiTextField-root': { m: 1, maxWidth: '100%' },
                    }}>
                    {formItems}
                    </Box>
                    <div className="clearfix"/>
                </div>
            );
        }
    }
}

export function ClientContact(props){
    const items = KClientContactFields();
    return <Form { ...props} items={items}/>;
}

function AddressForm(props) {
    const items = KAddressFields;
    return <Form {...props} items={items}/>;
}

export function DeliveryPointForm(props) {
    const items = KDeliveryPointFields(props.onRecordDetail);
    return <Form { ...props} items={items}/>;
}

export function YearConsumptionForm(props) {
    const items = KYearConsumptionFields;
    return <Form {...props} items={items} />;
}

export function OfferRecipientForm(props) {
    const items = KOfferRecipient(props.onRecordDetail);
    return <Form {...props} items={items}/>;
}

export function ClientDocumentForm(props) {
    const items = KDocumentFields();
    return <Form { ...props} items={items}/>;
}

export function NoteForm(props) {
    const items = KNoteFields();
    return <Form { ...props} items={items}/>;
}

export function AppointmentForm(props) {
    const items = KAppointmentFields();
    return <Form {...props} items={items} />;
}

export function CompanyForm(props) {
    const items = KCompanyFields;
    return <Form { ...props} items={items}/>;
}

export function OwnCompanyForm(props) {
    const items = KOwnCompanyFields;
    return <Form {...props} items={items}/>;
}

export function SettingsForm(props) {
    const items= [
        {
            field: 'username',
            headerName: 'Uživatelské jméno',
            type: 'text',
            readOnly: true
        }, {
            field: 'name',
            headerName: 'Jméno',
            type: 'text'
        },{
            field: 'surname',
            headerName: 'Příjmení',
            type: 'text'
        }, {
            field: 'created',
            headerName: 'Datum vytvoření',
            readOnly: true,
            type: 'dateTime'
        }
    ];
    return <Form { ...props} items={items}/>;
}

export function Template(props) {
    return <TemplateForm {...props}
        onFormSave={props.onRecordSave}
        onFormReset={props.onRecordReset}
        onFormClose={props.onWindowClose} />
}

export function TemplateForm(props) {
    const items = KTemplateFields();
    return <Form { ...props} items={items}/>;
}

export function Task(props) {
    return <TaskForm {...props}
        onFormSave={props.onRecordSave}
        onFormReset={props.onRecordReset}
        onFormClose={props.onWindowClose} />
}

export function TaskForm(props) {
    const items = KTaskFields();
    return <Form { ...props} items={items}/>;
}

export function OfferSettingsForm(props) {
    let templates = {};
    props.templates.forEach((tpl) => {
        if(!tpl.deleted) {
            templates[tpl.id] = tpl.name;
        }
    });
    const items = KOfferSettings(templates);
    return <Form {...props} items={items}/>;
}

export default ClientContact;
