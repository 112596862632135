import React from 'react';
import {
    ClientContact,
    DeliveryPointForm,
    ClientDocumentForm,
    NoteForm,
    CompanyForm,
    AppointmentForm,
    YearConsumptionForm, OfferRecipientForm
} from "./Forms";
import RecordTable from "./RecordTable";
import BasicTabbedPane from "./TabbedPane";

import AddIcon from '@mui/icons-material/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from "@mui/material/Button";
import {GridActionsCellItem} from '@mui/x-data-grid-pro';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import {PrimaryAddButton} from "./Components";
import {
    KDeliveryPointFields,
    KDocumentFields,
    KNoteFields,
    KAppointmentFields,
    KDeliveryPointsListFields
} from "./RecordFields";
import {Fab} from "@mui/material";
import {companyProcessed, deliveryPointProcessed, formatRecordTitle} from "./Functions";
//import {memoize} from 'react-data-table-component';

function ClientCompany(props) {

    const newDeliveryPointButton = <Button key={101} onClick={props.onNewDeliveryPoint} startIcon={<AddIcon/>}>Nové odběrné místo</Button>;
    return <div className="client_details">
        <Company data={props.data}
                 originalData={props.originalData}
                onRecordChanged={props.onRecordChanged}
                 onRecordSave={props.onRecordSave}
                 onRecordReset={props.onRecordReset}
                 onRecordMove={() => props.onRecordMove(props.data, 'client_company')}
                onRecordDelete={() => props.onRecordDelete(props.data, 'client_company')}/>
        <RecordTable title="Odběrná místa"
                     name="delivery_points_list"
                     actions={[newDeliveryPointButton]}
                     rowClass={(dp) => deliveryPointProcessed(dp)}
                     contextActions={[
                         {
                             title: 'Smazat vybrané',
                             onClick: (records) => props.onRecordsDelete(records, 'delivery_point'),
                             activeOnly: true
                         },
                         {
                             title: 'Obnovit smazané',
                             onClick: (records) => props.onRecordsRestore(records, 'delivery_point'),
                             deletedOnly: true
                         },
                         {
                             title: 'Vytvořit cenovou nabídku',
                             onClick: (records) => props.onRecordsExport(records, 'delivery_point'),
                             activeOnly: true
                         },
                         {
                             title: 'Přesunout vybrané',
                             onClick: (records) => props.onRecordsMove(records, 'delivery_point'),
                             activeOnly: true
                         },
                         {
                             title: 'Nová hromadná smlouva',
                             onClick: (records) => props.onNewMassDeliveryPointDocument(records),
                             activeOnly: true
                         }
                     ]}
                     columns={KDeliveryPointFields(props.onRecordDetail, props.onRecordDelete, props.onRecordMove)}
                     recordType="delivery_point"
                     records={ (props.data.delivery_point && props.data.delivery_point.records)}
                     onRecordDetail={props.onRecordDetail}/>
    </div>;
}

function ClientDetailsTab(props) {
    const companies = !props.data.client_company ? '' :
        props.data.client_company.records.map((company, idx) => { return {
            title: formatRecordTitle(company, 'client_company'),
            deleted: !!company.deleted,
            headerClass: companyProcessed(company),
            content: <ClientCompany
                key={idx}
                onRecordSave={(company, changes) => props.onRecordSave(company, changes, 'client_company')}
                onRecordReset={props.onRecordReset}
                onRecordChanged={(cmp) => {
                    let copy = {...props.data};
                    copy.client_company.records[idx] = cmp;
                    props.onRecordChanged(copy);
                }}
                onRecordDelete={props.onRecordDelete}
                onRecordsDelete={props.onRecordsDelete}
                onRecordsRestore={props.onRecordsRestore}
                onRecordsExport={props.onRecordsExport}
                onRecordsMove={props.onRecordsMove}
                onNewMassDeliveryPointDocument={props.onNewMassDeliveryPointDocument}
                onRecordDetail={props.onRecordDetail}
                onRecordMove={props.onRecordMove}
                onNewDeliveryPoint={function (){props.onNewDeliveryPoint(company.id)}}
                data={company}
                originalData={(props.originalData.client_company && props.originalData.client_company.records[idx]) || {}}/>} });
    return <div className="client_details">
        <ClientContact data={props.data} originalData={props.originalData}
                       onFormSave={props.onRecordSave}
                       onFormReset={props.onRecordReset}/>
        <div className="clearfix"/>
        {!props.data.id ? '' :
            <BasicTabbedPane currentTab={(props.data.client_company && props.data.client_company.active_record) || 0} setCurrentTab={props.onActiveCompanyChange} tabs={companies} actions={[
                {title: 'Nová společnost', action: props.onNewCompany, startIcon: <AddIcon/>}

            ]} />
        }
    </div>
}

function ClientDocumentsTab(props) {
    const newDocButton = <Button key={1} onClick={props.onNewDocument} startIcon={<AddIcon/>}>Nový dokument</Button>;
    return <div className="client_details">
            <RecordTable height="75vh"
                         name="client_documents"
                         title=""
                         actions={newDocButton}
                         contextActions={[
                             {
                                 title: 'Smazat vybrané',
                                 onClick: (records) => props.onRecordsDelete(records, 'client_document'),
                                 activeOnly: true
                             },
                             {
                                 title: 'Obnovit smazané',
                                 onClick: (records) => props.onRecordsRestore(records, 'client_document'),
                                 deletedOnly: true
                             }
                         ]}
                         columns={KDocumentFields(props.onRecordDetail, props.onRecordOpen, props.onRecordDownload, props.onRecordDelete, 'client_document')}
                         recordType='client_document'
                         records={props.data.document && props.data.document.records}
                         onRecordDetail={props.onRecordDetail}/>
        </div>;
}

function DeliveryPointDocuments(props) {
    const newDocButton = <Button key={1} onClick={props.onNewDocument} startIcon={<AddIcon/>}>Nový dokument</Button>;
    return <RecordTable height="500px"
                 name="delivery_point_documents_list"
                 title=""
                 actions={newDocButton}
                 treePath={(document) =>  [document.files ? document.files : 'Nezařazeno', document.id]}
                 contextActions={[
                     {
                         title: 'Smazat vybrané',
                         onClick: (records) => props.onRecordsDelete(records, 'delivery_point_document'),
                         activeOnly: true
                     },
                     {
                         title: 'Obnovit smazané',
                         onClick: (records) => props.onRecordsRestore(records, 'delivery_point_document'),
                         deletedOnly: true
                     }
                 ]}
                 columns={KDocumentFields(props.onRecordDetail, props.onRecordOpen, props.onRecordDownload, props.onRecordDelete, props.onNewDocument,'delivery_point_document')}
                 recordType='delivery_point_document'
                 records={props.data.delivery_point_document && props.data.delivery_point_document.records}
                 onRecordDetail={props.onRecordDetail}/>
}

function ClientNotesTab(props) {
    const newButton = <Button key={1} onClick={props.onNewNote} startIcon={<AddIcon />}>Nová poznámka</Button>;
    return <div className="client_details">
        <RecordTable height="75vh" title=""
                     name="client_notes_list"
                     actions={newButton}
                     contextActions={[
                         {
                             title: 'Smazat vybrané',
                             onClick: (records) => props.onRecordsDelete(records, 'client_note'),
                             activeOnly: true
                         },
                         {
                             title: 'Obnovit smazané',
                             onClick: (records) => props.onRecordsRestore(records, 'client_note'),
                             deletedOnly: true
                         }
                     ]}
                     columns={KNoteFields(props.onRecordDetail, props.onRecordDelete, props.onRecordRestore)}
                     recordType='client_note'
                     records={props.data.customer_note ? props.data.customer_note.records.slice().reverse() : []}/>
    </div>;
}

function ClientAppointmentTab(props) {
    const newButton = <Button key={1} onClick={props.onNewAppointment} startIcon={<AddIcon />}>Nová schůzka</Button>;
    return <div className="client_details">
        <RecordTable height="75vh" title=""
                     name="client_appointments_list"
                     actions={newButton}
                     contextActions={[
                         {
                             title: 'Smazat vybrané',
                             onClick: (records) => props.onRecordsDelete(records, 'client_appointment'),
                             activeOnly: true
                         },
                         {
                             title: 'Obnovit smazané',
                             onClick: (records) => props.onRecordsRestore(records, 'client_appointment'),
                             deletedOnly: true
                         }
                     ]}
                     columns={KAppointmentFields(props.onRecordDetail, props.onRecordDelete, props.onRecordRestore)}
                     recordType='client_appointment'
                     records={props.data.client_appointment && props.data.client_appointment.records.slice().reverse()}/>
    </div>;
}

export class Client extends React.Component {

    render() {
        const title = this.props.data.id ?
            'Klient ' + (this.props.data.name || '') + ' '+ (this.props.data.surname || ''):
            'Nový klient';

        if(this.props.onPageRender) {
            this.props.onPageRender({
                title: title
            });
        }
        let tabs = [{title: 'Detaily a společnosti', content: <ClientDetailsTab {...this.props}/>}];
        if(this.props.data.id) {
            tabs.push(
                {title: 'Dokumenty', content: <ClientDocumentsTab {...this.props}/>},
                {title: 'Poznámky', content: <ClientNotesTab {...this.props}/>},
                {title: 'Schůzky', content: <ClientAppointmentTab {...this.props}/>}
            );
        }
        return (
            <div>
                <h1>{title}</h1>
                <BasicTabbedPane tabs={tabs} currentTab={this.props.currentPage} setCurrentTab={this.props.setCurrentPage} />
            </div>
        )
    }
}

export class DeliveryPoint extends React.Component {
    render() {
        const title = this.props.data.id ?
            'Odběrné místo ' + formatRecordTitle(this.props.data, 'delivery_point'):
            'Nové odběrné místo';
        const buttons = <Fab color="secondary" aria-label="preview" onClick={() => this.props.onExportPreview()} ><PreviewIcon/></Fab>;
        const documents = !this.props.data.id || this.props.data.id<0? '' : <DeliveryPointDocuments {...this.props} />;
        return (
            <div>
                <h1>{title}</h1>
                <DeliveryPointForm {...this.props}
                               onFormSave={this.props.onRecordSave}
                               onFormReset={this.props.onRecordReset}
                               buttons={buttons}/>
                {documents}
            </div>
        )
    }
}

export class YearConsumption extends React.Component {
    render() {
        return <div>
            <YearConsumptionForm {...this.props}
                onFormSave={this.props.onRecordSave}
                />
        </div>
    }
}

export class OfferRecipient extends React.Component {
    render() {
        return <div>
            <OfferRecipientForm {...this.props}
                onFormSave={this.props.onRecordSave}
                />
        </div>
    }
}

export function ClientDocument(props) {
    let massDocument = undefined;
    if(Array.isArray(props.data.delivery_point_id)){
        const ids = props.data.delivery_point_id;
        const filteredDeliveryPoints = props.allDeliveryPoints.filter(item => ids.includes(item.id));
        const isNew = !props.data.id;
        massDocument = <RecordTable height="45vh" title="Test"
                                    deletedFilter={false}
                                    contextActions={isNew ? [] : [{
                                        title: 'Odebrat vybrané',
                                        onClick: (records) => props.onRemoveDeliveryPoints(props.data, records),
                                        activeOnly: true
                                    }]}
                                    actions={isNew ? [] : [<Button key="add_dp" onClick={(records) => props.onAddDeliveryPoints(props.data, filteredDeliveryPoints, props.allDeliveryPoints)}>Přidat odběrné místo</Button>
                                    ]}
                name="document_delivery_points"
                columns={KDeliveryPointsListFields}
                recordType='delivery_point_document'
                records={filteredDeliveryPoints}/>
    }
    return (
        <div>
        <ClientDocumentForm {...props}
                           onFormSave={props.onRecordSave}
                           onFormReset={props.onRecordReset}
                           onFormClose={props.onWindowClose}/>
            {massDocument}
        </div>
    )
}

export function Note(props) {
    return <NoteForm {...props}
                     onFormSave={props.onRecordSave}
                     onFormReset={props.onRecordReset}
                     onFormClose={props.onWindowClose}/>
}

export function Appointment(props) {
    return <AppointmentForm {...props}
                     onFormSave={props.onRecordSave}
                     onFormReset={props.onRecordReset}
                     onFormClose={props.onWindowClose}/>
}

export function Company(props) {
    return <CompanyForm {...props}
                    onFormSave={props.onRecordSave}
                    onFormReset={props.onRecordReset}
                    onFormClose={props.onWindowClose}
                    onFormDelete={props.onRecordDelete}
                    onFormMove={props.onRecordMove}/>
}

export default Client;