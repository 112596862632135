import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';

//Original licence
LicenseInfo.setLicenseKey('225aa6911d08a392ea0617457eee1d02Tz00NTE3MixFPTE2ODYxMjM2NzI2NTYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
//Updated licence to pass verification
//LicenseInfo.setLicenseKey('c8009e49f91458f1fa794edc13918791Tz00NTE3MixFWFBJUlk9MTY4NjEyMzY3MjY1NixTPXBybyxMTT1zdWJzY3JpcHRpb24sS1Y9Mg==');
let webUrl = window.location.href.replace(window.location.search, '');
if(webUrl[webUrl.length-1]!=='/') {
    webUrl += '/';
}
const apiUrl = (webUrl.indexOf('localhost')>=0 ?
    (webUrl+'api/').replace('localhost:3000', 'localhost:444/mn') :
    webUrl+'api/')
    .replace('/office', '').replace('/onedrive','');
ReactDOM.render(
  //<React.StrictMode>
    <App apiUrl={apiUrl}/>,
  //</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* Only register a service worker if it's supported */
console.log('before registering worker');
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
    .then((registrations) => registrations.forEach((registration) => {
        console.log('registering worker');
        registration.unregister()
    }));
    //navigator.serviceWorker.register('service-worker.js');
}

/**
 * Warn the page must be served over HTTPS
 * The `beforeinstallprompt` event won't fire if the page is served over HTTP.
 * Installability requires a service worker with a fetch event handler, and
 * if the page isn't served over HTTPS, the service worker won't load.
 */
/*if (window.location.protocol === 'http:') {
    const requireHTTPS = document.getElementById('requireHTTPS');
    if(requireHTTPS) {
        const link = requireHTTPS.querySelector('a');
        link.href = window.location.href.replace('http://', 'https://');
        requireHTTPS.classList.remove('hidden');
    }
}

window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent the mini-infobar from appearing on mobile.
    event.preventDefault();
    console.log('👍', 'beforeinstallprompt', event);
    // Stash the event so it can be triggered later.
    window.deferredPrompt = event;
    // Remove the 'hidden' class from the install button container.
    divInstall.classList.toggle('hidden', false);
});

if(butInstall) {
    butInstall.addEventListener('click', async () => {
        console.log('👍', 'butInstall-clicked');
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            // The deferred prompt isn't available.
            return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        const result = await promptEvent.userChoice;
        console.log('👍', 'userChoice', result);
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
        // Hide the install button.
        divInstall.classList.toggle('hidden', true);
    });
}

window.addEventListener('appinstalled', (event) => {
    console.log('👍', 'appinstalled', event);
    // Clear the deferredPrompt so it can be garbage collected
    window.deferredPrompt = null;
});*/