import React from "react";
import {PrimaryButton} from "./Components";
import {downloadBlob} from "./Functions";
import {TextField} from "@mui/material";
import {KOfferRecipient} from "./RecordFields";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export class ExportDetails extends React.Component {

    constructor(props) {
        super(props);

        let recipients = [];
        props.recipients.forEach((recipient) => {
            let recipientAttachments = [];
            this.props.exportData.files.forEach((file) => {
                const field = this.sheetNameToField(file.sheetName);
                if(recipient[field]) {
                    recipientAttachments.push(file);
                }
            });
            recipients.push({
                ...recipient,
                subject: this.props.email.send_subject,
                body: this.props.email.send_body,
                attachments: recipientAttachments,
                expanded: false,
                errors: {},
                state: recipientAttachments.length > 0 ? 'new' : 'unavailable'
            });
        });
        this.state = {
            recipients: recipients
        };
    }

    sheetNameToField(sheetName) {
        const cols = KOfferRecipient();
        for (const col of cols) {
            if(col.headerName===sheetName) {
                return col.field;
            }
        }
        return undefined;
    }

    updateRecipient(idx, changes) {
        let recipients = [...this.state.recipients];
        for (const prop in changes) {
            recipients[idx][prop] = changes[prop];
        }
        this.setState({
            recipients: recipients
        });
    }

    sendEmailToAllRecipients() {
        for(let i=0; i<this.state.recipients.length; i++) {
            this.sendEmailToRecipient(i);
        }
    }

    sendEmailToRecipient(idx) {
        const recipient = this.state.recipients[idx];
        if(recipient.state!=='new') {
            return; //already pending or sent
        }
        this.updateRecipient(idx, {
            state: 'sending'
        });
        this.props.office.sendEmail({
            recipients: recipient.email,
            subject: recipient.subject,
            body: recipient.body,
            attachments: recipient.attachments
        }).then(() => {
            this.updateRecipient(idx, {
                state: 'sent',
                errors: {}
            });
        }, errors => {
            this.updateRecipient(idx, {
                errors: errors,
                expanded: true,
                state: 'new'
            });
        });
    }

    render() {
        const title = "Vytvoření cenové nabídky";
        if(this.props.onPageRender) {
            this.props.onPageRender({
                title: title
            });
        }
        /*{
            blob: blob,
            name: suggestedName + '.xlsx',
            expiration: context.expiration,
            sheetName: context.name,
            index: context.wbIndex
        }*/
        const files = this.props.exportData.files.map((file) => {
            return <div>{file.name}<PrimaryButton onClick={() => downloadBlob(file.blob, file.name)}>Stáhnout</PrimaryButton></div>;
        });

        const emails = this.state.recipients.map((recipient, idx) => {
            function stateToButton(state) {
                switch (state) {
                    case 'sending':
                        return 'Odesílám';
                    case 'sent':
                        return 'Odesláno';
                    default:
                        return 'Odeslat';
                }
            }
            if(!recipient.attachments || recipient.attachments.length===0) {
                return <div>
                    <h4>{recipient.name} {recipient.surname}</h4>
                    <p>Pro tohoto příjemce není žádné vhodné odběrné místo</p>
                </div>
            }
            const recipientFiles = recipient.attachments.map((file) => {
                return <div>{file.name}<PrimaryButton onClick={() => downloadBlob(file.blob, file.name)}>Stáhnout</PrimaryButton></div>;
            });
            const expander = recipient.expanded ?
                <ExpandLessIcon fontSize={"large"} onClick={() => this.updateRecipient(idx, {expanded:false})} /> :
                <ExpandMoreIcon fontSize={"large"} onClick={() => this.updateRecipient(idx, {expanded:true})} />;
            return <div>
                <h2>{expander}
                    {recipient.name} {recipient.surname} ({recipient.email})
                    <PrimaryButton disabled={recipient.state!=='new' || !this.props.office.isLogged()}
                                   onClick={() => this.sendEmailToRecipient(idx) }
                    >{stateToButton(recipient.state)}</PrimaryButton>
                </h2>
                {!recipient.expanded ? undefined :
                    <div>
                    <span>{recipient.errors.recipients}{recipient.errors.send}</span>
                    <TextField fullWidth
                    label={'Předmět'}
                    helperText={recipient.errors.subject}
                    error={!!recipient.errors.subject}
                    value={recipient.subject}
                    onChange={(e) => {
                    this.updateRecipient(idx, {
                    subject: e.currentTarget.value
                });
                }}
                    />
                    <TextField fullWidth multiline={true}
                    maxRows="8" label={'Tělo zprávy'}
                    helperText={recipient.errors.body}
                    error={!!recipient.errors.body}
                    defaultValue={recipient.body}
                    onChange={(e) => {
                    this.updateRecipient(idx, {
                    body: e.currentTarget.value
                });
                }}
                    />
                    <h4>Přílohy</h4>
                {recipientFiles}
                    </div>
                }
            </div>
        });

        const anyUnsentRecipient = this.state.recipients.some((recipient) => recipient.state==='new');
        return (
            <div>
                <h1>Vytvořené nabídky</h1>
                {files}
                <br/><br/>
                <h1>Odeslání nabídek <PrimaryButton
                    disabled={!anyUnsentRecipient || !this.props.office.isLogged()}
                    onClick={() => this.sendEmailToAllRecipients()}>Odeslat vše</PrimaryButton></h1>
                {!this.props.office.isLogged() ? <p>POZOR není přihlášen účet k odesílání pošty. Přihlašte se v Cenové nabídky / Nastavení Emailu / Odesílatel</p> : undefined}
                {emails}
            </div>
        )
    }
}