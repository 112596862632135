const AresApp = function () {
    let _cache = {};
    let _apiUrl;
    function init(apiUrl) {
        _apiUrl = apiUrl;
    }

    function getIco(ico) {
        if(_cache[ico]) {
            return Promise.resolve(_cache[ico]);
        }
        return fetch(_apiUrl+'ares/'+ico)
            .then(response => response.json())
            .then(data => {
                if(data.pocetCelkem>0) {
                    _cache[ico] = data.ekonomickeSubjekty[0];
                    return _cache[ico];
                }
                return null;
            }).catch((err) => null);
    }


    return {
        initApi: init,
        getIco: getIco
    };
}();
export default AresApp;