import React from "react";
import {PrimaryButton} from "./Components";
import {
    DataGridPro,
    csCZ,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridFooterContainer,
    GridToolbarQuickFilter,
    GridFooter
} from '@mui/x-data-grid-pro';

import Box from '@mui/material/Box';
import {
    KClientContactFields,
    KDeliveryPointListFields, KOfferRecipient,
    KTaskFields,
    KTemplateFields
} from "./RecordFields";
import Button from "@mui/material/Button";
import {baseStr, clientProcessed, deliveryPointProcessed, recordProcessed} from "./Functions";

function CustomToolbar(props) {
    const contextActions = (!props.selection || props.selection.length===0) ? [] : props.contextActions;
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            {props.actions}
            {contextActions}
            <GridToolbarQuickFilter
                quickFilterParser={(input) => {
                    const r = input.split(' ')
                    return r;
                }}
                quickFilterFormatter={(values) => values.join(' ')}
            />
        </GridToolbarContainer>
    );
}

/*function CustomFooter(props) {
    const actions = (!props.selection || props.selection.length===0) ? [] : props.actions;
    return (
        <GridFooterContainer>
            <Box sx={{ p: 1, m:1}}>{actions}</Box>
            <GridFooter />
        </GridFooterContainer>
    );
}*/

class RecordTable extends React.Component {
    constructor(props) {
        super(props);
        this.onRowClicked = this.onRowClicked.bind(this);
        this.state = {records: [], error: false, toggledClearRows: false, showDeletedRecords: false}
        this.render = this.render.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.isSelectedDeletedRow = this.isSelectedDeletedRow.bind(this);
        this.loadStoredColumnsOrder = this.loadStoredColumnsOrder.bind(this);
        this.loadStoredColumnsWidths = this.loadStoredColumnsWidths.bind(this);
        this.loadStoredColumnsSort = this.loadStoredColumnsSort.bind(this);
    }
    onRowClicked(params, event)
    {
        if(this.props.onRecordDetail) {
            this.props.onRecordDetail(params.row, this.props.recordType);
        }
    }
    componentDidMount() {
        /*if(!this.props.records && this.props.fetch) {
            this.props.fetch(this.props.recordType).then((records) => {
                this.setState({records: records, error: false});
            }).catch((error) => {
                this.setState({records: [], error: error.message});
            });
        }*/
    }

    isSelectedDeletedRow() {
        if(!this.state.selectedRows) {
            return false;
        }
        for(const r of this.state.selectedRows) {
            if(r.deleted) {
                return true;
            }
        }
        return false;
    }

    isSelectedNotDeletedRow() {
        if(!this.state.selectedRows) {
            return false;
        }
        for(const r of this.state.selectedRows) {
            if(!r.deleted) {
                return true;
            }
        }
        return false;
    }

    escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    loadStoredColumnsOrder() {
        if(this.props.name) {
            const k = 'mn.datagrid.'+this.props.name+'.columnOrder';
            const storedVal = localStorage.getItem(k);
            if(storedVal) {
                const parsed = JSON.parse(storedVal);
                return Array.isArray(parsed) ? parsed : [];
            }
        }
        return [];
    }

    loadStoredColumnsWidths() {
        if(this.props.name) {
            const k = 'mn.datagrid.'+this.props.name+'.columnWidths';
            const storedVal = localStorage.getItem(k);
            if(storedVal) {
                const parsed = JSON.parse(storedVal);
                return typeof parsed === "object" ? parsed : {};
            }
        }
        return {};
    }

    loadStoredColumnsSort() {
        if(this.props.name) {
            const k = 'mn.datagrid.'+this.props.name+'.columnSort';
            const storedVal = localStorage.getItem(k);
            if(storedVal) {
                const parsed = JSON.parse(storedVal);
                return Array.isArray(parsed) ? parsed : [];
            }
        }
        return [];
    }

    render() {
        if(this.state.error) {
            return <p>{this.state.error}</p>;
        }

        const contextActions = !this.props.contextActions ? [] :
            this.props.contextActions.map((item, idx) =>
                (item.deletedOnly && !this.isSelectedDeletedRow()) ||
                (item.activeOnly && !this.isSelectedNotDeletedRow()) ? '' :
                <PrimaryButton sx={{mr:1}} key={idx} onClick={() => {
                    item.onClick(this.state.selectedRows);
                    this.setState({selectionModel: [], selectedRows: []});
                }}>{item.title}</PrimaryButton>
            );
        let actions;
        if(!this.props.actions) {
            actions = [];
        }else {
            actions = Array.isArray(this.props.actions) ? [...this.props.actions] : [this.props.actions];
        }
        if(this.props.deletedFilter!==false) {
            actions.unshift(<Button key={-1}
                                    onClick={() => this.setState({showDeletedRecords: !this.state.showDeletedRecords})}>{(this.state.showDeletedRecords ? 'Skrýt smazané' : 'Zobrazit smazané')}</Button>);
        }

        //filtered records
        const widths = this.loadStoredColumnsWidths();
        let sortModel = this.loadStoredColumnsSort();
        const rows = this.props.fetch ? this.state.records : this.props.records;
        let columnVisibility = {
            deleted: false
        };
        let columns = this.props.columns.map((column) => {
            if(column.visibility!==undefined) {
                columnVisibility[column.field] = column.visibility;
            }
            let ret = {...column};
            if(!ret.getApplyQuickFilterFn) {
                ret.getApplyQuickFilterFn = (value) => {
                    if (!value) {
                        return null;
                    }
                    return (params) => {
                        return typeof params.value === "string" ?
                            baseStr(params.value).indexOf(baseStr(value))>=0 : false;
                            /*baseStr(params.value).search(new RegExp(value, "i")) >= 0 :
                            false;*/
                    };
                };
            }
            if(widths[ret.field]) {
                ret.width = widths[ret.field];
            }
            return ret;
        });
        let columnsOrder = this.loadStoredColumnsOrder();
        let remainingColumns = [...columns];
        let orderedColumns = [];
        for(const field of columnsOrder) {
            for(let i = 0; i<remainingColumns.length; i++) {
                if(remainingColumns[i].field===field) {
                    orderedColumns.push(remainingColumns[i]);
                    remainingColumns.splice(i, 1);
                    break;
                }
            }
        }
        //TODO place remaining columns close to defined order
        for(const field of remainingColumns) {
            orderedColumns.push(field);
            columnsOrder.push(field.field);
        }
        if(this.props.name) {
            localStorage.setItem('mn.datagrid.' + this.props.name + '.columnOrder', JSON.stringify(columnsOrder));
        }
        return (
            <div style={{ display: 'flex', height: this.props.height || '84vh' }}>
                <div style={{ flexGrow: 1 }}>
            <DataGridPro
                disableSelectionOnClick
                checkboxSelection={contextActions.length>0}
                title={this.props.title}
                disableColumnMenu={true}
                treeData={!!this.props.treePath}
                getTreeDataPath={(row) => this.props.treePath?.(row)}
                getDetailPanelContent={this.props.getDetailPanelContent}
                getDetailPanelHeight={this.props.getDetailPanelHeight}
                getRowClassName={(params) => params.row.deleted ? 'deleted-record' : (this.props.rowClass ? this.props.rowClass(params.row):'')}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                onColumnOrderChange={(columnOrder, p2, p3, p4) => {
                    let {field, targetIndex, oldIndex} = columnOrder; // Destructure column Name // and it's target Index
                    if(this.props.name) {
                        if(this.props.contextActions) { //when rows are selectable there is implicit column at the beginning
                            targetIndex--;
                            oldIndex--;
                        }
                        let newColumns = [...this.loadStoredColumnsOrder()];
                        newColumns.splice(targetIndex, 0, newColumns.splice(oldIndex, 1)[0]);
                        localStorage.setItem('mn.datagrid.'+this.props.name+'.columnOrder', JSON.stringify(newColumns));
                    }
                }
                }
                onColumnResize={(columnResize) => {
                    if(this.props.name) {
                        const field = columnResize.colDef.field;
                        const newWidth = columnResize.width;
                        let newWidths = {...this.loadStoredColumnsWidths()};
                        newWidths[field] = newWidth;
                        localStorage.setItem('mn.datagrid.' + this.props.name + '.columnWidths', JSON.stringify(newWidths));
                    }
                }
                }

                components={{
                    Toolbar: () => <CustomToolbar
                        actions={actions}
                        contextActions={contextActions}
                        selection={this.state.selectionModel}
                        onSearch={(search) => this.setState({search: search})}
                    />
                }}
                noDataComponent="Žádné záznamy"
                columns={orderedColumns}
                rowGroupingColumnMode="single"
                initialState={{
                    pinnedColumns: {left: ['detail']},
                    rowGrouping: {
                        model: ['files'],
                    },
                    columns: {
                        columnVisibilityModel: columnVisibility
                    },
                    filter: {
                        filterModel: {
                            items: [{columnField: 'deleted', operatorValue: 'is', value: 'false'}]
                        },
                    },
                    sorting: {
                        sortModel: sortModel
                    }
                }}
                rows={rows || []}
                onSelectionModelChange={(newSelectionModel) => {
                    this.setState({
                        selectionModel: newSelectionModel,
                        selectedRows: this.props.records ? this.props.records.filter(item => newSelectionModel.indexOf(item.id)!==-1) : []
                    })
                }}
                selectionModel={this.state.selectionModel}
                contextActions={contextActions}
                clearSelectedRows={this.state.toggledClearRows}
                sortmodel={sortModel}
                onSortModelChange={(newSortModel) => {
                    if(this.props.name) {
                        localStorage.setItem('mn.datagrid.'+this.props.name+'.columnSort', JSON.stringify(newSortModel));
                    }
                }}
                selectableRows
            />
            </div>
            </div>
        );
    }
}

function ClientDetails(row) {
    if (!row.client_company || row.client_company.records.length===0) {
        return null;
    }
    let lines = row.client_company.records.map((clientCompany) =>
        <div key={clientCompany.id}>{clientCompany.name} {clientCompany.surname} {clientCompany.company} {clientCompany.ico}</div>);
    return <Box>{lines}</Box>;
}

export function ClientsList(props) {
    if(props.onPageRender) {
        props.onPageRender({title: 'Seznam klientů'});
    }
    const getDetailPanelContent = React.useCallback(
        ({ row }) =>
            ClientDetails(row),
        [],
    );
    const getDetailsPanelHeight = React.useCallback(({row}) =>
        row.client_company ? row.client_company.records.length*20 : 0,
        [],
    );
    return (<div>
        <RecordTable {...props}
                    name="clients_list"
                     title='Seznam klientů'
                     columns={KClientContactFields(props.onRecordDetail, props.onRecordDelete)}
                     rowClass={(client) => clientProcessed(client)}
                     contextActions={[
                         {
                             title: 'Exportovat vybrané',
                             onClick: (records) => props.onRecordsExport(records, 'client'),
                             activeOnly: true
                         },
                         {
                             title: 'Smazat vybrané',
                             onClick: (records) => props.onRecordsDelete(records, 'client'),
                             activeOnly: true
                         },
                         {
                             title: 'Obnovit smazané',
                             onClick: (records) => props.onRecordsRestore(records, 'client'),
                             deletedOnly: true
                         }
                     ]}
                     getDetailPanelContent={getDetailPanelContent}
                     getDetailPanelHeight={getDetailsPanelHeight}
                     recordType="client"/>
    </div>);
}

export function DeliveryPointsList(props) {
    if(props.onPageRender) {
        props.onPageRender({title: 'Seznam odběrných míst'});
    }
    let records = [];
    let recordsReverseMap = {};
    props.records.forEach((client) => {
        if (!client.client_company || !client.client_company.records || client.deleted) {
            return;
        }
        client.client_company.records.forEach((company, companyIdx) => {
            if (!company.delivery_point || !company.delivery_point.records || company.deleted) {
                return;
            }
            company.delivery_point.records.forEach((dp) => {
                if(dp.deleted) {
                    return;
                }
                let updated = {...dp};
                updated.client = client;
                updated.company = company;
                records.push(updated)
                recordsReverseMap[dp.id] = {
                    parents: [{
                        recordType: 'client',
                        id: client.id
                    }, {
                        recordType: 'client_company',
                        id: company.id
                    }],
                    client: client,
                    company: companyIdx
                };
            });
        });
    });
    return (<div>
        <RecordTable {...props}
                     records={records}
                     name="all_delivery_points_list"
                     title='Seznam odběrných míst'
                     columns={KDeliveryPointListFields((record) => {
                         let parents = recordsReverseMap[record.id].parents;
                         props.onRecordDetail(record, 'delivery_point', null, parents);
                     }, null, null, (record) => {
                         let parents = recordsReverseMap[record.id];
                         props.onRecordParentDetail(record, parents.company, parents.client);
                     })}
                     rowClass={dp => deliveryPointProcessed(dp)}
                     contextActions={[
                         {
                             title: 'Exportovat vybrané',
                             onClick: (records) => props.onRecordsExport(records, 'delivery_point'),
                             activeOnly: true
                         }
                     ]}
                     recordType="delivery_point"/>
    </div>);
}

export function TemplateList(props) {
    if(props.onPageRender) {
        props.onPageRender({title: 'Šablony'});
    }

    return (<div>
        <RecordTable {...props}
                     name="templates_list"
                     title='Šablony'
                     columns={KTemplateFields(props.onRecordDetail, props.onRecordOpen, props.onRecordDownload)}
                     recordType="template"
                     contextActions={[
                         {
                             title: 'Smazat vybrané',
                             onClick: (records) => props.onRecordsDelete(records, 'template'),
                             activeOnly: true
                         },
                         {
                             title: 'Obnovit smazané',
                             onClick: (records) => props.onRecordsRestore(records, 'template'),
                             deletedOnly: true
                         }
                     ]}
        />
    </div>);
}

export function TaskList(props) {
    if(props.onPageRender) {
        props.onPageRender({title: 'Úkoly'});
    }

    return (<div>
        <RecordTable {...props}
                     name="tasks_list"
                     title='Úkoly'
                     columns={KTaskFields(props.onRecordDetail, props.onRecordOpen, props.onRecordDownload)}
                     recordType="task"
                     contextActions={[
                         {
                             title: 'Smazat vybrané',
                             onClick: (records) => props.onRecordsDelete(records, 'task'),
                             activeOnly: true
                         },
                         {
                             title: 'Obnovit smazané',
                             onClick: (records) => props.onRecordsRestore(records, 'task'),
                             deletedOnly: true
                         }
                     ]}
        />
    </div>);
}

export function OffersList(props) {
    if(props.onPageRender) {
        props.onPageRender({title: 'Cenové nabídky'});
    }

    return (<div>
        <RecordTable {...props}
                     name="offers_list"
                     title='Cenové nabídky'
                     columns={KOfferRecipient(props.onRecordDetail, props.onRecordOpen, props.onRecordDownload)}
                     recordType="offer_recipient"
                     contextActions={[
                         {
                             title: 'Smazat vybrané',
                             onClick: (records) => props.onRecordsDelete(records, 'offer_recipient'),
                             activeOnly: true
                         },
                         {
                             title: 'Obnovit smazané',
                             onClick: (records) => props.onRecordsRestore(records, 'offer_recipient'),
                             deletedOnly: true
                         }
                     ]}
        />
    </div>);
}

export default RecordTable;