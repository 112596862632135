import {GRID_DETAIL_PANEL_TOGGLE_FIELD, GridActionsCellItem} from "@mui/x-data-grid-pro";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import {
    baseStr, clientProcessed,
    dateQuickFilterFn,
    deliveryPointProcessed,
    clientDaysToContractEnd,
    enumApplyQuickFilterFn,
    fileNameValidator,
    findParentClientCompany,
    formatAddress,
    formatBytes,
    formatDate,
    formatDateTime,
    formatDefaultFileStorage,
    formatFile,
    formatNoticePeriod,
    formatRecordTitle,
    stringToFloat, companyProcessed, companyProcessedDays, processedLoc, deliveryPointDaysToContractEnd,
} from "./Functions";
import {CustomDetailPanelToggle} from "./Components";
import * as Exporter from './Exporter';

export const KClientContactFields = (openHandler, deleteHandler) => [
    {
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
        headerName: '',
        sortable: false,
        width: 30,
        renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />
    },{
        field: 'actions',
        type: 'actions',
        width: 70,
        getActions: (params) => [
            <GridActionsCellItem icon={<SearchIcon />} onClick={() => openHandler(params.row, 'client', true)} label="Detail"/>,
            <GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteHandler(params.row, 'client')} label="Smazat" showInMenu />
        ]
    },
    {
        headerName: 'Příjmení',
        field: 'surname',
        sortable: true
    },
    {
        headerName: 'Jméno',
        field: 'name',
        sortable: true,
        editable: true
    }, {

        field: 'company',
        type: "text",
        headerName: 'Společnost',
        valueGetter: (params) => {
            const row = params.api.getRow(params.id);
            if (row.client_company && row.client_company.records &&
                row.client_company.records.length > 0) {
                return row.client_company.records[0].company;
            }
            return '';
        },
        getApplyQuickFilterFn: (search) => {
            if (!search) {
                return null;
            }
            return (params) => {
                const regex = (new RegExp(baseStr(search), "i"));
                const row = params.row;
                if (row.client_company && row.client_company.records &&
                    row.client_company.records.length > 0) {
                    for (const company of row.client_company.records) {
                        for (const p in company) {
                            const val = company[p];
                            if (typeof val === "string" && baseStr(val).search(regex) >= 0) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            };
        },
        form: false,
        sortable: true,
        width: 160,
        readOnly: true
    },{
        headerName: 'Email',
        field: 'email',
        type: 'email',
        sortable: true
    }, {
        headerName: 'Telefon',
        field: 'phone',
        type: 'phone'
    },{
        field: 'files',
        type: "text",
        headerName: 'Adresář pro soubory',
        default: (params) => formatDefaultFileStorage(params.row),
        required: true,
        validator: fileNameValidator
    },{
        field: 'is_client',
        type: "boolean",
        headerName: 'Je klient'
    },
    {
        headerName: 'Poslední změna',
        field: 'modified',
        type: 'dateTime',
        valueFormatter: (params) => formatDateTime(params.value),
        sortable: true,
        width: 160,
        readOnly: true
    },
    {
        headerName: 'Nejbližší konec smlouvy',
        type: 'date',
        field: 'client_company',
        valueFormatter: (params) => {
            const company = params.value; //api.getRow(params.id)?.client_company;
            if (company) {
                const v = company;
                const val = (v['delivery_point.contract_expiration'] && v['delivery_point.contract_expiration'].min) || '';
                return formatDate(val);
            }
            return '';
        },
        sortComparator: (v1, v2) => {
            return (v1 && v1['delivery_point.contract_expiration'] ? (new Date(v1['delivery_point.contract_expiration'].min)).valueOf() : Number.MAX_SAFE_INTEGER) - (v2 && v2['delivery_point.contract_expiration'] ? (new Date(v2['delivery_point.contract_expiration'].min)).valueOf() : Number.MAX_SAFE_INTEGER)
        },
        getApplyQuickFilterFn: dateQuickFilterFn,
        sortable: true,
        readOnly: true,
        width: 160
    },
    {
        headerName: 'Zobchodováno',
        field: 'processed',
        type: 'text',
        sortable: true,
        valueFormatter: (params) => {
            const client = params.api.getRow(params.id);
            return processedLoc(clientProcessed(client));
        },
        valueGetter: (params) =>{
            return clientDaysToContractEnd(params.row);
        },
        /*sortComparator: (v1, v2) => {
            return (clientDaysToContractEnd(v1) - clientDaysToContractEnd(v2));
        },*/
        readOnly: true
    },
    {
        headerName: 'Nejbližší upozornění',
        type: 'dateTime',
        field: 'customer_note',
        valueFormatter: (params) => {
            if (params.value) {
                const v = params.value;
                const val = (v.notification && v.notification.min) || '';
                return formatDateTime(val);
            }
            return '';
        },
        sortComparator: (v1, v2) => {
            return (v1 && v1.notification ? (new Date(v1.notification.min)).valueOf() : Number.MAX_SAFE_INTEGER) - (v2 && v2.notification ? (new Date(v2.notification.min)).valueOf() : Number.MAX_SAFE_INTEGER)
        },
        sortable: true,
        readOnly: true,
        width: 160
    },
    {
        headerName: 'Nejbližší schůzka',
        type: 'dateTime',
        field: 'client_appointment',
        valueFormatter: (params) => {
            if (params.value) {
                const v = params.value;
                const val = (v.date && v.date.min) || '';
                return formatDateTime(val);
            }
            return '';
        },
        sortComparator: (v1, v2) => {
            return (v1 && v1.date ? (new Date(v1.date.min)).valueOf() : Number.MAX_SAFE_INTEGER) - (v2 && v2.date ? (new Date(v2.date.min)).valueOf() : Number.MAX_SAFE_INTEGER)
        },
        sortable: true,
        readOnly: true,
        width: 160
    },
    {
        headerName: 'Smazáno',
        field: 'deleted',
        readOnly: true,
        sortable: true,
        type: 'boolean'
    }
];

export const KAddressFields = [
    {
        field: 'street',
        headerName: 'Ulice',
        type: 'text'
    },{
        field: 'city',
        headerName: 'Město',
        type: 'text'
    }, {
        field: 'postal',
        headerName: 'PSČ',
        type: 'numeric'
    }
];


const KMonthNames = ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec'];
const KDeliveryPointTypeOptions = {gas: 'Plyn', electricity: 'Elektřina', electricity_gen: 'Elektřina výkup'};
export const KVoltageLevelOptions = ['NN', 'VN', 'VV'];
export const KGasLevelOptions = ['MO', 'SO', 'VO', 'DOM'];
export const KElectricityTariffOptions = ['C01d', 'C02d', 'C03d', 'C25d', 'C26d', 'C27d', 'C35d', 'C45d', 'C56d', 'C62d'];
export const KDeliveryPointFields = (detailsHandler, deleteHandler, moveHandler, openCompanyHandler) => [
    {
        field: 'actions',
        type: 'actions',
        width: 70,
        getActions: (params) => {
            let actions = [];
            if (detailsHandler) {
                actions.push(<GridActionsCellItem icon={<SearchIcon/>}
                                                  onClick={() => detailsHandler(params.row, 'delivery_point')}
                                                  label="Detail"/>);
            }
            if(openCompanyHandler) {
                actions.push(<GridActionsCellItem icon={<SearchIcon/>}
                                                  onClick={() => openCompanyHandler(params.row)}
                                                  label="Otevřít v kartě klienta" showInMenu/>);
            }
            if (moveHandler) {
                actions.push(<GridActionsCellItem icon={<DriveFileMoveIcon/>}
                                                  onClick={() => moveHandler(params.row, 'delivery_point')}
                                                  label="Přesunout" showInMenu/>);
            }
            if (deleteHandler) {
                actions.push(<GridActionsCellItem icon={<DeleteIcon/>}
                                                  onClick={() => deleteHandler(params.row, 'delivery_point')}
                                                  label="Smazat" showInMenu/>);
            }
            return actions;
        }
    },
    {
        headerName: 'Vlastní název',
        field: 'name',
        sortable: true
    },
    {
        headerName: 'EAN / EIC kód',
        field: 'ean',
        sortable: true
    },
    {
        headerName: 'Variabilní symbol',
        field: 'variable_number',
        sortable: true
    },
    {
        headerName: 'Zobchodováno',
        field: 'processed',
        sortable: true,
        valueFormatter: (params) => {
            const row = params.api.getRow(params.id);
            return processedLoc(deliveryPointProcessed(row));
        },
        valueGetter: (params) => {
            return deliveryPointDaysToContractEnd(params.row);
        },
        readOnly: true
    },
    {
        headerName: 'Typ odběrného místa',
        field: 'delivery_point_type',
        type: 'singleSelect',
        valueOptions: KDeliveryPointTypeOptions,
        valueFormatter: (params) => {
            if (params.value) {
                return KDeliveryPointTypeOptions[params.value] || params.value;
            }
            return '';
        },
        getApplyQuickFilterFn: (search) => {
            return (params) => enumApplyQuickFilterFn(params, search, KDeliveryPointTypeOptions);
        },
        sortable: true
    },
    {
        headerName: 'Adresa odběrného místa',
        field: 'address',
        type: 'address',
        valueFormatter: (params) => formatAddress(params.value),
        width: 200,
        sortable: true
    },
    {
        headerName: 'Roční spotřeba MWh',
        field: 'year_consumption',
        sortable: true,
        type: 'year_consumption',
        valueFormatter: (params) => {
            const row = params.api.getRow(params.id);
            const yc = row.year_consumption;
            //Electricity NN has default low/high
            let type = yc?.type || (row.delivery_point_type==='electricity' && row.voltage_level==='NN' ? 'low_high' : 'total');
            if(type==='monthly') {
                //sum all months
                let sum = 0;
                KMonthNames.forEach((month) => {
                    if (yc && yc[month]) {
                        sum += stringToFloat(yc[month]);
                    }
                });
                return sum;
            }else if(type==='low_high') {
                return stringToFloat(yc?.low) + stringToFloat(yc?.high);
            }else/* if(yc?.type==='total') */{
                return stringToFloat(yc?.high);
            }
        },
        condition: (params) => params.row.delivery_point_type!=='electricity' || params.row.voltage_level!=='NN'
    },
    {
        headerName: 'Roční spotřeba VT MWh',
        field: 'year_consumption.high',
        sortable: true,
        type: "numeric",
        condition: (params) => params.row.delivery_point_type==='electricity' && params.row.voltage_level==='NN'
    },
    {
        headerName: 'Roční spotřeba NT MWh',
        field: 'year_consumption.low',
        sortable: true,
        type: "numeric",
        condition: (params) => params.row.delivery_point_type==='electricity' && params.row.voltage_level==='NN'
    },
    {
        headerName: 'DRPK v m3',
        field: 'daily_reserved_capacity',
        sortable: true,
        type: "numeric",
        condition: (params) => params.row.delivery_point_type === 'gas' && params.row.gas_level === 'VO'
    },
    {
        headerName: 'MS',
        field: 'ms',
        sortable: true
    },
    {
        headerName: 'Hodnota jističe',
        field: 'circuit_breaker_level',
        sortable: true,
        condition: (params) => params.row.delivery_point_type === 'electricity' || params.row.delivery_point_type==='electricity_gen'
    },
    {
        headerName: 'Napěťová úroveň',
        field: 'voltage_level',
        type: 'singleSelect',
        sortable: true,
        valueOptions: KVoltageLevelOptions,
        condition: (params) => params.row.delivery_point_type==='electricity' || params.row.delivery_point_type==='electricity_gen'
    },
    {
        headerName: 'Napěťová úroveň plyn',
        field: 'gas_level',
        type: 'singleSelect',
        sortable: true,
        valueOptions: KGasLevelOptions,
        condition: (params) => params.row.delivery_point_type==='gas'
    },
    {
        headerName: 'Typ měření',
        field: 'measurement_type',
        sortable: true
    },
    {
        headerName: 'Distribuční sazba',
        field: 'distribution_tarrif',
        valueOptions: KElectricityTariffOptions,
        sortable: true,
        condition: (params) => params.row.delivery_point_type === 'electricity'
    },
    {
        headerName: 'Současný dodavatel',
        field: 'supplier',
        sortable: true
    },
    {
        headerName: 'Nutnost zasílání výpovědi KAM',
        field: 'reject_kam',
        type: 'boolean',
        sortable: true
    },
    {
        headerName: 'Konec stávající smlouvy',
        field: 'contract_expiration',
        type: 'date',
        valueFormatter: (params) => formatDate(params.value),
        getApplyQuickFilterFn: dateQuickFilterFn,
        sortable: true
    },
    {
        headerName: 'Výpovědní lhůta',
        field: 'notice_period',
        sortable: true
    },
    {
        headerName: 'Druh ZD',
        field: 'zd_type',
        sortable: true
    },
    {
        headerName: 'Výše zálohy',
        field: 'zaloha',
        sortable: true
    },
    {
        headerName: 'Cyklus záloh',
        field:'period',
        type: 'singleSelect',
        valueOptions: ['měsíčně', 'čtvrtletně', 'půlročně','ročně'],
        sortable: true
    },
    {
        headerName: 'Elektronická faktura',
        field: 'electronic_invoice',
        type: 'boolean',
        sortable: true
    },
    {
        headerName: 'Email pro el. fakturaci',
        field: 'electronic_invoice_email',
        sortable: true
    },
    {
        headerName: 'Datum změny',
        field: 'modified',
        valueFormatter: (params) => formatDateTime(params.value),
        readOnly: true,
        sortable: true,
    },
    {
        headerName: 'Smazáno',
        field: 'deleted',
        readOnly: true,
        sortable: true,
        type: 'boolean'
    }
];


export const KDeliveryPointsListFields = [
    {
        headerName: 'Vlastní název',
        field: 'name',
        sortable: true
    },
    {
        headerName: 'EAN / EIC kód',
        field: 'ean',
        sortable: true
    },
    {
        headerName: 'Variabilní symbol',
        field: 'variable_number',
        sortable: true
    },
    {
        headerName: 'Typ odběrného místa',
        field: 'delivery_point_type',
        type: 'singleSelect',
        valueOptions: KDeliveryPointTypeOptions,
        valueFormatter: (params) => {
            if (params.value) {
                return KDeliveryPointTypeOptions[params.value] || params.value;
            }
            return '';
        },
        getApplyQuickFilterFn: (search) => {
            return (params) => enumApplyQuickFilterFn(params, search, KDeliveryPointTypeOptions);
        },
        sortable: true
    },
    {
        headerName: 'Adresa odběrného místa',
        field: 'address',
        type: 'address',
        valueFormatter: (params) => formatAddress(params.value),
        width: 200,
        sortable: true
    }
];

const KYearConsumptionType = {
    total: 'Celková roční',
    low_high: 'Roční vysoký / nízký tarif',
    monthly: 'Měsíční rozpad'
};

export const KYearConsumptionFields = [
    {
        headerName: 'Způsob zadání',
        field: 'type',
        type: 'singleSelect',
        valueOptions: KYearConsumptionType,
        default: (params) => 'total'
    },
    {
        headerName: 'Roční spotřeba [MWh]',
        field: 'high',
        type: "numeric",
        condition: (params) => params.row.type==='total' ||!params.row.type
    }, {
        headerName: 'Roční spotřeba VT [MWh]',
        field: 'high',
        type: "numeric",
        condition: (params) => params.row.type === 'low_high'
    }, {
        headerName: 'Roční spotřeba NT [MWh]',
        field: 'low',
        type: "numeric",
        condition: (params) => params.row.type === 'low_high'
    }, {
        headerName: 'Spotřeba Leden [MWh]',
        field: 'jan',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Únor [MWh]',
        field: 'feb',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Březen [MWh]',
        field: 'mar',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Duben [MWh]',
        field: 'apr',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Květen [MWh]',
        field: 'may',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Červen [MWh]',
        field: 'jun',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Červenec [MWh]',
        field: 'jul',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Srpen [MWh]',
        field: 'aug',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Září [MWh]',
        field: 'sep',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Říjen [MWh]',
        field: 'oct',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Listopad [MWh]',
        field: 'nov',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }, {
        headerName: 'Spotřeba Prosinec [MWh]',
        field: 'dec',
        type: "numeric",
        condition: (params) => params.row.type === 'monthly'
    }
];


export const KDeliveryPointListFields = (detailsHandler, deleteHandler, moveHandler, openCompanyHandler) =>
{
    let baseFields = KDeliveryPointFields(detailsHandler, deleteHandler, moveHandler, openCompanyHandler);
    const parentFields = [
        {
            headerName: 'Příjmení klienta',
            field: 'client_surname',
            valueGetter: (params) => {
                const row = params.api.getRow(params.id);
                return row.client.surname;
            },
            sortable: true
        },
        {
            headerName: 'Jméno klienta',
            field: 'client_name',
            valueGetter: (params) => {
                const row = params.api.getRow(params.id);
                return row.client.name;
            },
            sortable: true,
            editable: true
        }, {

            headerName: 'Společnost',
            field: 'company',
            type: "text",
            valueGetter: (params) => {
                const row = params.api.getRow(params.id).client;
                const cc = findParentClientCompany(row, params.id);
                return cc ? formatRecordTitle(cc, 'client_company') : '';
            },
            getApplyQuickFilterFn: (search) => {
                if (!search) {
                    return null;
                }
                return (params) => {
                    const regex = (new RegExp(baseStr(search), "i"));
                    const row = params.row.client;
                    const cc = findParentClientCompany(row, params.id);
                    if(cc){
                        for (const p in cc) {
                            const val = cc[p];
                            if (typeof val === "string" && baseStr(val).search(regex) >= 0) {
                                return true;
                            }
                        }
                    }
                    return false;
                };
            },
            form: false,
            sortable: true,
            width: 160,
            readOnly: true
        }
    ];
    baseFields.splice(1,0, ...parentFields);
    return baseFields;
}

const KDocumentType = {
    smlouva: 'Smlouva',
    podepsana_smlouva: 'Podepsaná smlouva',
    plna_moc: 'Plná moc',
    vop: 'VOP',
    predsmluvni_informace: 'Předsmulvní informace',
    prihloha_smlouvy: 'Příloha ke smlouvě',
    puvodni_faktura: 'Původní faktura',
    aktualni_faktura: 'Aktuální faktura',
    vypoved_smlouvy: 'Výpověď smlouvy',
    jine: 'Jiný typ'
};
export const KDocumentFields = (detailsHandler, openHandler, downloadHandler, deleteHandler, newHandler, recordType) => [
    {
        field: 'actions',
        type: 'actions',
        width: 70,
        getActions: (params) => {
            if (!params.row.id) {
                return [newHandler ? <GridActionsCellItem icon={<AddIcon/>} onClick={() => newHandler(params.id.split('/')[1])} label="Přidat dokument do skupiny"/> : undefined];
            }
            return [
                <GridActionsCellItem icon={<SearchIcon/>} onClick={() => detailsHandler(params.row, recordType)}
                                     label="Detail"/>,
                /*<GridActionsCellItem icon={<FileOpenIcon />} onClick={() => openHandler(params.row, recordType)} label="Otevřít" showInMenu />,*/
                <GridActionsCellItem icon={<SaveIcon/>} onClick={() => downloadHandler(params.row, recordType)}
                                     disabled={!params.row.file || params.row.file.missing}
                                     label="Stáhnout" showInMenu/>,
                <GridActionsCellItem icon={<DeleteIcon/>} onClick={() => deleteHandler(params.row, recordType)}
                                     label="Smazat" showInMenu/>
            ]
        }
    }, {
        field: 'files',
        headerName: 'Skupina',
        type: 'text',
        visibility: false,
        validator: fileNameValidator
    },{
        field: 'name',
        headerName: 'Název',
        type: 'text'
    },
    {
        field: 'description',
        headerName: 'Popis',
        type: 'textarea'
    },
    {
        headerName: 'Typ dokumentu',
        field: 'type',
        type: 'singleSelect',
        valueOptions: KDocumentType,
        valueFormatter: (params) => {
            if (params.value) {
                return KDocumentType[params.value] || params.value;
            }
            return '';
        },
    },
    {
        field: 'accord_type',
        headerName: 'Typ smlouvy',
        type: 'singleSelect',
        valueOptions: ['S prolongací', 'Bez prolongace'],
        condition: (params) => params.row.type==='smlouva' || params.row.type==='podepsana_smlouva'
    },
    {
        field: 'supplier',
        headerName: 'Dodavatel',
        type: 'text',
        condition: (params) => params.row.type==='smlouva' || params.row.type==='podepsana_smlouva'
    },
    {
        field: 'contract_expiration_type',
        headerName: 'Ukonční smouvy',
        type: 'singleSelect',
        valueOptions: {
            unknown: 'Neznámé',
            indefinite: 'Na dobu neurčitou',
            date: 'Ke dni'
        },
        visibility: false,
        condition: (params) => (params.row.type==='smlouva' || params.row.type==='podepsana_smlouva')
    },
    {
        field: 'contract_expiration',
        headerName: 'Datum ukončení smlouvy',
        type: 'date',
        valueGetter: (params) => {
            const row = params.row;
            if(row.type!=='smlouva' && params.row.type!=='podepsana_smlouva') {
                return '-';
            }
            if(row.contract_expiration_type==='indefinite') {
                return 'Na dobu neurčitou';
            }else if(row.contract_expiration_type === 'unknown' || !params.value) {
                return 'Neznámé';
            }
            return params.value;
        },
        getApplyQuickFilterFn: dateQuickFilterFn,
        valueFormatter: (params) => {
            const row = params.api.getRow(params.id);
            return params.value === row.contract_expiration ? formatDate(params.value) : params.value;
        },
        condition: (params) => params.row.contract_expiration_type==='date' && (params.row.type==='smlouva' || params.row.type==='podepsana_smlouva')
    },
    {
        field: 'notice_period',
        headerName: 'Výpovědní lhůta',
        type: 'numeric',
        valueFormatter: (params) => {
            const row = params.api.getRow(params.id);
            return formatNoticePeriod(row);
        },
        valueGetter: (params) => {
            const row = params.row;
            let coef = 1;
            if(row.notice_period_type==='month') {
                coef = 30;
            }else if(row.notice_period_type==='year') {
                coef = 365;
            }
            return (params.value || 0) * coef;
        },
        condition: (params) => (params.row.type==='smlouva' || params.row.type==='podepsana_smlouva') && (params.row.contract_expiration_type==='indefinite' || params.row.contract_expiration_type==='date')
    },
    {
        field: 'notice_period_type',
        headerName: '',
        type: 'singleSelect',
        valueGetter: (params) => {
            return params.value || 'day';
        },
        valueOptions: {
            day: 'Dní',
            month: 'Měsíců',
            year: 'Let'
        },
        editOnly: true,
        visibility: false,
        condition: (params) => (params.row.type==='smlouva' || params.row.type==='podepsana_smlouva') && (params.row.contract_expiration_type==='indefinite' || params.row.contract_expiration_type==='date')
    },
    {
        headerName: 'Soubor',
        field: 'file',
        type: 'file',
        sortable: true,
        required: true,
        width: 500,
        valueFormatter: (params) => formatFile(params.value)
    },
    {
        headerName: 'Datum změny',
        field: 'modified',
        valueFormatter: (params) => formatDateTime(params.value),
        width: 160,
        readOnly: true
    },
    {
        headerName: 'Smazáno',
        field: 'deleted',
        readOnly: true,
        sortable: true,
        type: 'boolean'
    }
];

export const KNoteFields = (detailsHandler) => [
    {
        field: 'actions',
        type: 'actions',
        width: 70,
        getActions: (params) => [
            <GridActionsCellItem icon={<SearchIcon />} onClick={() => detailsHandler(params.row, 'client_note')} label="Detail" />
        ]
    },{
        headerName: 'Text',
        field: 'text',
        type: 'textarea',
        width: 500
    },
    {
        headerName: 'Datum upozornění',
        field: 'notification',
        type: 'dateTime',
        valueFormatter: (params) => formatDateTime(params.value),
        sortable: true,
        width: 160
    },
    {
        headerName: 'Splněno',
        field: 'is_completed',
        type: "boolean"
    },
    {
        headerName: 'Datum vytvoření',
        field: 'created',
        type: 'dateTime',
        valueFormatter: (params) => formatDateTime(params.value),
        readOnly: true,
        sortable: true,
        width: 160
    }, {
        headerName: 'Smazáno',
        field: 'deleted',
        readOnly: true,
        type: 'boolean'
    }/*,
    {
        name: 'Akce',
        cell: (row) => [
            <Button onClick={() => deleteHandler(row, 'client_note')}>Smazat</Button>
        ]
    }*/
];

export const KTaskFields = (detailsHandler) => [
    {
        field: 'actions',
        type: 'actions',
        width: 70,
        getActions: (params) => [
            <GridActionsCellItem icon={<SearchIcon />} onClick={() => detailsHandler(params.row, 'task')} label="Detail" />
        ]
    },{
        headerName: 'Text',
        field: 'text',
        type: 'textarea',
        width: 500
    },
    {
        headerName: 'Splnit do',
        field: 'deadline',
        type: 'dateTime',
        valueFormatter: (params) => formatDateTime(params.value),
        sortable: true,
        width: 160
    },
    {
        headerName: 'Splněno',
        field: 'is_completed',
        type: "boolean"
    },
    {
        headerName: 'Datum vytvoření',
        field: 'created',
        type: 'dateTime',
        valueFormatter: (params) => formatDateTime(params.value),
        readOnly: true,
        sortable: true,
        width: 160
    }, {
        headerName: 'Smazáno',
        field: 'deleted',
        readOnly: true,
        type: 'boolean'
    }
];

export const KOfferRecipient = (detailsHandler) => [
    {
        field: 'actions',
            type: 'actions',
        width: 70,
        getActions: (params) => [
        <GridActionsCellItem icon={<SearchIcon />} onClick={() => detailsHandler(params.row, 'offer_recipient')} label="Detail" />
    ]
    },{
        headerName: 'Jméno',
        field: 'name',
        type: 'text',
        sortable: true,
    },
    {
        headerName: 'Příjmení',
        field: 'surname',
        type: 'text',
        sortable: true,
    },
    {
        headerName: 'Email',
        field: 'email',
        type: "email",
        sortable: true
    },
    {
        headerName: 'EE MOP',
        field: 'electricity_nn',
        type: 'boolean',
        sortable: true
    },
    {
        headerName: 'EE VO',
        field: 'electricity_others',
        type: 'boolean',
        sortable: true
    },
    {
        headerName: 'ZP MO',
        field: 'gas_mo',
        type: "boolean",
        sortable: true
    },
    {
        headerName: 'ZP VO',
        field: 'gas_others',
        type: "boolean",
        sortable: true
    },
    {
        headerName: 'Datum vytvoření',
            field: 'created',
        type: 'dateTime',
        valueFormatter: (params) => formatDateTime(params.value),
        readOnly: true,
        sortable: true,
        width: 160
    }, {
        headerName: 'Smazáno',
            field: 'deleted',
            readOnly: true,
            type: 'boolean'
    }
];

export const KAppointmentFields = (detailsHandler) => [
    {
        field: 'actions',
        type: 'actions',
        width: 70,
        getActions: (params) => [
            <GridActionsCellItem icon={<SearchIcon />} onClick={() => detailsHandler(params.row, 'client_appointment')} label="Detail" />
        ]
    }, {
        headerName: 'Proběhla',
        field: 'is_completed',
        type: "boolean",
        sortable: true
    },{
        headerName: 'Popis',
        field: 'description',
        type: 'textarea',
        width: 500
    },
    {
        headerName: 'Datum schůzky',
        field: 'date',
        type: 'dateTime',
        valueFormatter: (params) => formatDateTime(params.value),
        sortable: true,
        width: 160
    },{
        headerName: 'Místo',
        field: 'address',
        type: 'address',
        valueFormatter: (params) => formatAddress(params.value),
        sortable: true,
    },{
        headerName: 'Osoba',
        field: 'appointee',
        type: 'text',
        sortable: true,
    },
    {
        headerName: 'Datum vytvoření',
        field: 'created',
        type: 'dateTime',
        valueFormatter: (params) => formatDateTime(params.value),
        readOnly: true,
        sortable: true,
        width: 160
    }, {
        headerName: 'Smazáno',
        field: 'deleted',
        readOnly: true,
        type: 'boolean'
    }/*,
    {
        name: 'Akce',
        cell: (row) => [
            <Button onClick={() => deleteHandler(row, 'client_note')}>Smazat</Button>
        ]
    }*/
];

const KCompanyTypeOptions = {company:'Společnost', person:'Osoba', osvc: 'Podnikatel'};
export const KCompanyFields = [
    {
        field: 'type',
        headerName: 'Typ',
        type: 'singleSelect',
        valueOptions: KCompanyTypeOptions,
        valueFormatter: (params) => {
            if (params.value) {
                return KCompanyTypeOptions[params.value] || params.value;
            }
            return '';
        },
    },
    {
        headerName: 'Příjmení',
        field: 'surname',
        sortable: true
    },
    {
        headerName: 'Jméno',
        field: 'name',
        sortable: true,
        editable: true
    },
    {
        field: 'company',
        headerName: 'Název',
        type: 'text',
        condition: (params) => params.row.type==='company'
    },{
        field: 'ico',
        headerName: 'IČO',
        type: 'ico',
        condition: (params) => params.row.type!=='person'
    },{
        field: 'dic',
        headerName: 'DIČ',
        type: 'text',
        condition: (params) => params.row.type==='company'
    },
    {
        field: 'birth',
        headerName: 'Datum narození',
        type: 'text',
        condition: (params) => params.row.type === 'person'
    },{
        field: 'address',
        type: 'address',
        valueFormatter: (params) => formatAddress(params.value)
    }, {
        field: 'bank_account',
        headerName: 'Číslo účtu/kód banky',
        type: 'text'
    }, {
        field: 'files',
        type: "text",
        headerName: 'Adresář pro soubory',
        default: (params) => formatDefaultFileStorage(params.row),
        required: true,
        validator: fileNameValidator
    },{
        headerName: 'Zobchodováno',
        field: 'processed',
        sortable: true,
        valueFormatter: (params) => {
            const row = params.api.getRow(params.id);
            return processedLoc(companyProcessed(row));
        },
        sortComparator: (v1, v2) => {
            return (companyProcessedDays(v1) - companyProcessedDays(v2));
        },
        readOnly: true
    },{
        field: 'created',
        headerName: 'Datum vytvoření',
        readOnly: true,
        valueFormatter: (params) => formatDateTime(params.value),
        type: 'dateTime'
    }
];

export const KOwnCompanyFields = [
    {
        headerName: 'Příjmení',
        field: 'surname',
        sortable: true
    },
    {
        headerName: 'Jméno',
        field: 'name',
        sortable: true,
        editable: true
    },
    {
        field: 'company',
        headerName: 'Název',
        type: 'text'
    },{
        field: 'ico',
        headerName: 'IČO',
        type: 'text'
    },{
        field: 'dic',
        headerName: 'DIČ',
        type: 'text'
    },{
        field: 'address',
        type: 'address',
        valueFormatter: (params) => formatAddress(params.value)
    }, {
        field: 'bank_account',
        headerName: 'Číslo účtu/kód banky',
        type: 'text'
    }, {
        field: 'eon_id',
        headerName: 'EON ID',
        type: 'text'
    }, {
        field: 'modified',
        headerName: 'Změněno',
        readOnly: true,
        valueFormatter: (params) => formatDateTime(params.value),
        type: 'dateTime'
    }
];

export const KTemplateFields  = (detailHandler, openHandler, downloadHandler) => [
    {
        field: 'actions',
        type: 'actions',
        width: 100,
        getActions: (params) => [
            <GridActionsCellItem icon={<SearchIcon/>} onClick={() => detailHandler(params.row, 'template')} label="Detail" />,
            <GridActionsCellItem icon={<FileOpenIcon/>} disabled={!params.row.file} onClick={() => openHandler(params.row, 'template')} label="Otevřít" />,
            <GridActionsCellItem icon={<SaveIcon/>} disabled={!params.row.file} onClick={() => downloadHandler(params.row, 'template')} label="Stáhnout" />
        ]
    },
    {
        headerName: 'Název',
        field: 'name',
        sortable: true,
        width: 200
    },
    {
        headerName: 'Soubor',
        field: 'file',
        type: 'file',
        sortable: true,
        valueFormatter: (params) => formatFile(params.value),
        onChange: (api, newVal, data) => {
            Exporter.extractFillingSchema(newVal ? newVal.data : null).then((result) => {
                    //let fileVal = {...newVal};
                    //fileVal.data = result.template; //To replace uploaded file
                    api.updateFields({file: newVal, fill_template: JSON.stringify(result.schema)}, data);
                }/*,
                (err) => api.updateFields({file: newVal}, data)*/
            );
        },
        width: 500
    },
    {
        headerName: 'Formát exportu',
        field: 'fill_template',
        type: 'textarea',
        width: 500
    },
    {
        headerName: 'Adresy k odeslání',
        field: 'send_emails'
    },
    {
        headerName: 'Předmět zprávy',
        field: 'send_subject'
    },
    {
        headerName: 'Tělo zprávy',
        field: 'send_body',
        type: 'textarea',
        width: 500
    },
    {
        headerName: 'Datum změny',
        field: 'last_modification',
        readOnly: true,
        valueFormatter: (params) => formatDateTime(params.value),
        width: 160
    },
    {
        headerName: 'Smazáno',
        field: 'deleted',
        readOnly: true,
        sortable: true,
        type: 'boolean'
    }
];

export const KOfferSettings = (templates) => [
    {
        headerName: 'Předmět zprávy',
        field: 'send_subject',
        width: 200
    },
    {
        headerName: 'Tělo zprávy',
        field: 'send_body',
        type: 'textarea',
        width: 400
    },
    {
        headerName: 'Export do přílohy',
        field: 'attachment',
        type: 'singleSelect',
        valueOptions: templates,
        valueFormatter: (params) => {
            if (params.value) {
                return templates[params.value];
            }
            return '';
        },
    }
];