import { fromDataAsync, fromBlankAsync } from 'xlsx-populate';
import {formatRecordTitle, stringToFloat} from "./Functions";
import {KDeliveryPointFields} from "./RecordFields";

function evalData(data, key) {
    if(key[0]==='{' && key[key.length-1]==='}') {
        key = key.substring(1, key.length-1);
    }
    const parts = key.split('.');
    let d = data;
    for(const part of parts) {
        d = d[part];
        if(d===undefined) {
            return '';
        }
    }
    return typeof d !== "object" ? d : '';
}

function formatValue(format, data) {
    let ret = format;
    const matches = format.match(/{[^}]+}/gm);
    for(const match of matches){
        ret = ret.replaceAll(match, evalData(data, match));
    }
    return ret;
}

function extractSchema(workbook) {
    let schema = {};
    for(let sheet of workbook.sheets()) {
        let sheetSchema = {fields: []};
        sheet.forEachExistingRow(function (row){
            if(row.maxUsedColumnNumber()<0) {
                return;
            }
            for(let col = row.minUsedColumnNumber(); col<=row.maxUsedColumnNumber(); col++) {
                let cell = row.cell(col);
                let val = cell.value();
                if(typeof val !== "string") {
                    continue;
                }
                let field = {
                    cell: cell.address()
                };
                //Row can have optional format:
                //#condition:condition parameter#row value
                //Or only:
                //row value
                let schemedCell = false;
                while(true) {
                    const firstMatch = [...val.matchAll(/^(#([a-zA-Z_]+):?([^#]*)#)?(.*)$/gm)][0];
                    if (firstMatch[1]) {
                        //conditional directive
                        if (firstMatch[2] === 'sheet_condition') {
                            sheetSchema.condition = firstMatch[3];
                        }else if(firstMatch[2]==='sheet_records'){
                            sheetSchema.records = firstMatch[3];
                        } else if (firstMatch[2] === 'row') {
                            field.row_offset = firstMatch[3];
                        } else if(firstMatch[2] === 'column') {
                            field.column_offset = firstMatch[3];
                        }else if(firstMatch[2] === 'row_group'){
                            field.row_group = firstMatch[3];
                        } else if (firstMatch[2] === 'sum') {
                            field.aggregate = 'sum';
                            if (firstMatch[3]) {
                                field.condition = firstMatch[3];
                            }
                        } else if (firstMatch[2] === 'if') {
                            field.condition = firstMatch[3];
                        } else if(firstMatch[2] === 'num') {
                            field.filter = (val) => Number(val || 0).toFixed(firstMatch[3]);
                        } else if(!firstMatch[2]) {
                            break;
                        } else if(firstMatch[2] === 'cell') {
                            field.cell = firstMatch[3];
                        }else {
                            console.warn('Unknown conditional directive: '+firstMatch[2]);
                        }
                        schemedCell = true;
                        cell.value(firstMatch[4]);
                        val = firstMatch[4];
                    }else {
                        break;
                    }
                }
                //All {param} placeholders in value are replaced
                const matches = val.match(/{[^}]+}/gm);
                if(matches) {
                    field.value = val;
                    sheetSchema.fields.push(field);
                }
                if(schemedCell || matches) {
                    cell.value('');
                }
            }
        });
        if(sheetSchema.fields.length>0) {
            schema[sheet.name()] = sheetSchema;
        }
    }
    return schema;
}

export function extractFillingSchema(template) {
    if(!template) {
        return Promise.resolve(null);
    }
    return fromDataAsync(template).then((workbook) => {
        const schema = extractSchema(workbook);
        return workbook.outputAsync({type: 'arraybuffer'})
            .then((serialized) => {return {template: serialized, schema: {sheets: schema}}});
    });
}

export function exportClientDeliveryPoints(client) {
    return fromBlankAsync().then((workbook) => {
        for(const company of client.client_company.records) {
            if(company.deleted) {
                continue;
            }
            let sheet = workbook.addSheet(formatRecordTitle(company, 'client_company').substring(0, 30));
            let columns = KDeliveryPointFields(null, null, null, null)
                .slice(1, -1); //remove actions and deleted
            let companyCols = [
                formatRecordTitle(company, 'client_company'),
                company.ico,
                company.bank_account
            ];
            let data = [];
            let headers = ['Společnost', 'IČO', 'Číslo účtu'];
            for(const column of columns) {
                headers.push(column.headerName);
            }
            data.push(headers);
            if(company.delivery_point) {
                for (const dp of company.delivery_point.records) {
                    let row = [...companyCols];
                    for(const column of columns) {
                        let val = dp[column.field] || '';
                        if(column.valueFormatter) {
                            val = column.valueFormatter({
                                value: val
                            });
                        }
                        if(column.condition && !column.condition({
                            row: dp
                        })) {
                            val = '';
                        }
                        row.push(val);
                    }
                    data.push(row);
                }
            }
            //Put data to the sheet
            sheet.cell('A1').value(data);
        }
        if(workbook.sheets().length>1) {
            //Delete default sheet
            workbook.sheet(0).delete();
        }
        return workbook.outputAsync({type: 'blob'})
            .then((blob) => { return {
                blob: blob,
                name: formatRecordTitle(client, 'client')+'.xlsx'
            }});
    });
}

export function copyRanges(sourceRange, destRange) {
    sourceRange.forEach((cell, rowIndex, columnIndex) => {
        const destCell = destRange.cell(rowIndex, columnIndex);

        // get style object from current cell
        const style = cell.style([
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'fontSize',
            'fontFamily',
            'fontColor',
            'horizontalAlignment',
            'verticalAlignment',
            'wrapText',
            'shrinkToFit',
            'textDirection',
            'textRotation',
            'verticalText',
            'fill',
            'border',
            'numberFormat',
        ]);

        // set the values of the destination cell
        destCell.value(cell.value());

        // set the styles of the cell
        destCell.style(style);
    });
}

function linearize(data, prefix, output){
    if(prefix){
        prefix+=".";
    }
    for (const dataKey in data) {
        const k = prefix+dataKey;
        const val = data[dataKey];
        if(val===null || val===undefined){
            continue;
        }
        if(typeof val==="object"){
            if(val.records !== undefined){
                //skip sub records
            }else{
                linearize(val, k, output);
            }
        }else{
            output[k] = val;
        }
    }
}

export function exportDataRaw(data, columns) {
    //linearize data
    let linearData = [];
    const allKeys = {};
    data.forEach(row => {
        let linearized = {};
        linearize(row, '', linearized);
        linearData.push(linearized);
        for (const linearizedKey in linearized) {
            if(!allKeys[linearizedKey]){
                allKeys[linearizedKey] = indexToCellIdentifier(Object.keys(allKeys).length);
            }
        }
    });
    return fromBlankAsync().then(wb => {
        const sheet = wb.sheet(0);
        //Write headers
        for (const allKeysKey in allKeys) {
            sheet.cell(allKeys[allKeysKey]+"1").value(columns?.[allKeysKey] || allKeysKey);
        }
        let rowIdx = 2;
        linearData.forEach(row => {
            for (const rowKey in row) {
                sheet.cell(allKeys[rowKey] + rowIdx.toString()).value(row[rowKey]);
            }
            rowIdx++;
        });
        return wb.outputAsync({type: 'blob'});
    });
}

export function copyWorkbook(source) {
    return source.outputAsync({type: 'blob'})
        .then((blob) => fromDataAsync(blob));
}

export function exportData(template, schema, data) {
    if(!schema.sheets) {
        //Invalid schema
    }
    return fromDataAsync(template).then((workbook) => {
        const extractedSchema = extractSchema(workbook); //cleanse the workbook
        if(!schema) {
            schema = extractedSchema;
        }
        /*if (!workbook.Props || !workbook.Props.Application) {
            return Promise.reject('Nepodarilo se otevrit sablonu pro export');
        }*/

        let report = [];
        let fullWorkbooks = [];
        let exportedWorkbooks = [];
        async function iterateData() {
            for (let rowIndex=0; rowIndex < data.length; rowIndex++) {
                const row = data[rowIndex];
                //Work throught the rows
                for (let sheetIndex=0; sheetIndex < Object.keys(schema.sheets).length; sheetIndex++) {
                    const sheetName = Object.keys(schema.sheets)[sheetIndex];
                    let templateSheet = workbook.sheet(sheetName);
                    if (!templateSheet) {
                        report.push('List ' + sheetName + ' z definice nebyl v sablone nalezen');
                        continue;
                    }
                    const sheetSchema = schema.sheets[sheetName];
                    //let rowCntr = 0;
                    if (sheetSchema.condition && !evaluateCondition(sheetSchema.condition, row, report)) {
                        continue;
                    }
                    let expiration = row.contract_expiration || 'now';
                    let context = null;
                    let contextIdx = 0;
                    for(let findContext of exportedWorkbooks) {
                        if(findContext.name === sheetName && findContext.expiration === expiration) {
                            context = findContext;
                            break;
                        }
                        contextIdx++;
                    }
                    let wbIndex = 1;
                    if(context && sheetSchema.records && sheetSchema.records <= context.rowCntr) {
                        //Full sheet, move pending sheet to full ones
                        fullWorkbooks.push(context);
                        exportedWorkbooks.splice(contextIdx, 1);
                        wbIndex = context.wbIndex+1;
                        context = null;
                    }
                    if (!context) {
                        context = await copyWorkbook(workbook).then((wb) => {
                            for(const sheet of wb.sheets()) {
                                if(sheet.name()!==sheetName) {
                                    wb.deleteSheet(sheet);
                                }
                            }
                            //fromBlankAsync().then((wb) => {
                            //wb.cloneSheet(templateSheet, sheetName, 0);
                            //wb.deleteSheet(1);
                            //wb.sheet(0).name(sheetName);
                            //copyRanges(templateSheet.range('A1:BA110'), wb.sheet(0).range('A1:BA110'));
                            //wb.sheet(0).cell('A1').value();
                            //wb.addSheet(sheetName, templateSheet);
                            return {
                                name: sheetName,
                                expiration: expiration,
                                rowCntr: 0,
                                wbIndex: wbIndex,
                                workbook: wb
                            };
                        });
                        exportedWorkbooks.push(context);
                    }
                    let sheet = context.workbook.sheet(sheetName);
                    //Fill the cells
                    for (const field of sheetSchema.fields) {
                        let cell = field.cell;
                        let formatted = formatValue(field.value, row);
                        if (field.condition && !evaluateCondition(field.condition, row, report)) {
                            sheet.cell(cell).value('');
                            continue;
                        }
                        //compute cell
                        let rowGroup = field.row_group ? parseInt(field.row_group) : 1;
                        if (field.row_offset) {
                            const colRow = cell.match(/^([A-Z]+)(\d+)$/m);
                            //dynamic row
                            cell = colRow[1] + (parseInt(colRow[2]) + field.row_offset * Math.floor(context.rowCntr / rowGroup)).toString();
                        }
                        if (field.column_offset) {
                            const colRow = cell.match(/^([A-Z]+)(\d+)$/m);
                            //Convert alpha column to index
                            let colIndex = cellColumnToIndex(colRow[1]);
                            if (field.row_group) {
                                colIndex += field.column_offset * Math.floor(context.rowCntr % rowGroup);
                            } else {
                                colIndex += field.column_offset * context.rowCntr;
                            }
                            //dynamic column
                            cell = indexToCellIdentifier(colIndex) + colRow[2];
                        }
                        if (field.aggregate === 'sum') {
                            const oldVal = stringToFloat(sheet.cell(cell).value());
                            sheet.cell(cell).value(stringToFloat(formatted) + oldVal);
                        } else {
                            if(field.filter) {
                                formatted = field.filter(formatted);
                            }
                            sheet.cell(cell).value(formatted);
                        }
                    }
                    context.rowCntr++;
                }
            }
            return Promise.resolve();
        }
        return iterateData().then(() => {
            //group all workbooks
            fullWorkbooks.push(...exportedWorkbooks)
            //generate xls files
            let ops = [];
            let generatedFiles = [];
            for(const context of fullWorkbooks) {
                ops.push(context.workbook.outputAsync({type: 'blob'})
                    .then((blob) => {
                        const suggestedName = context.name + ' ' + context.expiration.toString() + ' ' + context.wbIndex.toString();
                        generatedFiles.push({
                            blob: blob,
                            name: suggestedName + '.xlsx',
                            expiration: context.expiration,
                            sheetName: context.name,
                            index: context.wbIndex
                        });
                    })
                );
            }
            return Promise.all(ops).then(() => {
                return{
                report: report,
                files: generatedFiles
            }});
        });
    });
}

function cellColumnToIndex(column)
{
    let idx = 0;
    for(let i = column.length-1; i>=0; i--) {
        let c = column[i].charCodeAt(i) - "A".charCodeAt(0);
        idx*=26;
        idx+=c;
    }
    return idx;
}

function indexToCellIdentifier(idx) {
    idx++;
    let ret = "";
    do {
        let rem = (idx-1)%26;
        ret = String.fromCharCode(rem + "A".charCodeAt(0)) + ret;
        idx=Math.floor((idx-1)/26);
    }while(idx>0);
    return ret;
}

function evaluateCondition(condition, data, report) {
    let filledCondition = formatValue(condition, data);
    filledCondition = filledCondition.replaceAll('(', '_').replace(/[‘’`´"']/g, "'");
    try {
        // eslint-disable-next-line no-eval
        const evaluatedCondition = eval(filledCondition);
        if (evaluatedCondition !== true) {
            return false;
        }
    }catch (e) {
        if(report) {
            report.push('Nepodarilo se vyhodnotit podminku: ' + filledCondition);
            report.push(e.toString());
        }
        console.warn('Failed to evaulate condition: ' + filledCondition);
        console.warn(e);
        return false;
    }
    return true;
}
