import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {IconButton, Menu, Popover} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid-pro";

export function PrimaryButton(props) {
    return <Button variant="contained" {...props} />
}

export function PrimaryAddButton(props) {
    return <Button startIcon={<AddIcon/>} variant="contained" {...props} />
}

export function PrimaryExportButton(props) {
    return <Button startIcon={<FileDownloadIcon/>} variant="contained" {...props} />
}

export function SecondaryButton(props) {
    return <Button variant="outlined" {...props} />
}

export function ButtonSpacer(props) {
    return <span className="button_spacer" />
}

export function CloseButton(props) {
    return <Button startIcon={<CloseIcon/>} {...props} />
}

export function EditButton(props) {
    return <Button startIcon={<EditIcon/>} {...props} />
}

export function PopoverMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <span>
        <SecondaryButton aria-describedby={id}
                         onClick={handleClick}
                         endIcon={<KeyboardArrowDownIcon />}>
            {props.title}
        </SecondaryButton>
        <Menu
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {props.children}
        </Menu>
        </span>
    );
}

export function CustomDetailPanelToggle(props) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}