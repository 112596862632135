
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    if(!bytes){
        return 'Neznámé';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function excelTimeToDate(serial) {
    if(isNaN(serial)) {
        let parts = serial.split(' ');
        let date = new Date();
        parts.forEach(part => {
            if(part.length===10) {
                //date
                let dateParts = part.split('.');
                if(dateParts.length===3) {
                    //DD.MM.YYYY
                    date.setFullYear(dateParts[2], dateParts[1]-1, dateParts[0]);
                }else {
                    dateParts = part.split('/');
                    //YYYY/MM/DD
                    date.setFullYear(dateParts[0], dateParts[1]-1, dateParts[2]);
                }
            }else {
                //time
                let timeParts = part.split(':');
                if(timeParts.length>2) {
                    date.setHours(timeParts[0], timeParts[1], timeParts[2]);
                }else {
                    date.setHours(timeParts[0], timeParts[1]);
                }
            }
        });
        return date;
    }
    const utc_days  = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}

function fixDateString(date) {
    if(typeof date === "string") {
        if(date.length>=19 && date[10]!=='T') {
            date = date.substring(0, 10) + 'T' + date.substring(11);
            if(date.length===19) {
                date= date+'.000Z';
            }
        }
    }
    return date;
}

export function formatDate(date) {
    const d = new Date(fixDateString(date));
    return date ? d.toLocaleDateString('cs-CZ') : '';
}

export function formatDateTime(date) {
    if(!date){
        return '';
    }
    const d = new Date(fixDateString(date));
    return date ? d.toLocaleString('cs-CZ') : '';
}

export function formatDefaultFileStorage(record) {
    if(record.company) {
        return record.company;
    }
    if(record.name || record.surname) {
        return ((record.surname || '') + ' ' + (record.name || '')).trim();
    }
    return '';
}

export function fileNameValidator(name) {
    if(/[~"#%&*:<>?\/\\{|}]/.test(name)) {
        return 'Pole obsahuje nepovolený znak: ~ " # % & * : < > ? / \\ { | }.';
    }
    return true;
}

export function formatFile(file) {
    if(typeof file !== 'object') {
        return '';
    }
    if(file.size) {
        return file.filename + ' [' + formatBytes(file.size) + "]"; // \nZměněno: "+formatDateTime(file.modified);
    }
    return file.filename;
}

export function formatAddress(address) {
    if (address) {
        const addr = address;
        return (addr.street ? addr.street + ', ' : '') + (addr.postal || '') + ' ' + addr.city;
    }
    return '';
}

export function compareValue(val) {
    if(!val) {
        return val;
    }
    if(val instanceof Date) {
        return val.getTime();
    }
    return val;
}

export function downloadBlob(blob, name) {
    const link = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = link;
    if(name) {
        a.download = name;
    }
    a.click();
    window.URL.revokeObjectURL(link);
}

const collator = new Intl.Collator('cs', { usage: 'search', sensitivity: 'base' });

export function baseStr(str) {
    if(!str) {
        return '';
    }
    function match(letter) {
        function letterMatch(letter, normalizedLetter) {
            var location = collator.compare(letter, normalizedLetter);
            return (location === 0)
        }
        const normalizedLetter = letter.normalize('NFD').replace(/[\u0300-\u036f]/gi, "")
        if ( letterMatch(letter, normalizedLetter) ) {
            return normalizedLetter;
        } else {
            return letter;
        }
    }
    return str.trim().replace(/[^\00-\7E]/g, match).toLowerCase();
}

export function formatRecordTitle(record, type) {
    if(type==='client') {
        return (record.name || '') + ' '+ (record.surname || '');
    }else if(type==='delivery_point') {
        return record.name || record.ean;
    }else if(type==='client_company') {
        return record.company || ((record.name || '') + ' ' + (record.surname || ''))
    }
    return record.name || formatRecordType(type);
}

export function formatRecordType(type) {
    if(type==='client_note') {
        return 'Poznámka';
    }
    return type;
}

export function enumApplyQuickFilterFn (params, search, options) {
    if(!search || typeof params.value !== "string") {
        return !search;
    }
    const regex = (new RegExp(search.trim(), "i"));
    const displayValue = options[params.value];
    if(!displayValue) {
        return false;
    }
    return baseStr(displayValue).search(regex) >= 0 || params.value.search(regex) >= 0;
}

export function dateQuickFilterFn (search) {return (params) => dateApplyQuickFilterFn(params, search);}

export function dateApplyQuickFilterFn(params, search) {
    if(!search || typeof params.value !== "string") {
        return !search;
    }
    const regex = (new RegExp(baseStr(search), "i"));
    const displayValue = formatDate(params.value);
    if(!displayValue) {
        return false;
    }
    const displayTrim = displayValue.replaceAll(' ', '');
    return displayValue.search(regex) >= 0 || displayTrim.search(regex) >=0 || baseStr(params.value).search(regex) >= 0;
}

export function findParentClientCompany(client, deliveryPointId) {
    if (client.client_company && client.client_company.records) {
        for (const cc of client.client_company.records) {
            if (cc.delivery_point && cc.delivery_point.records) {
                for (const dp of cc.delivery_point.records) {
                    if (dp.id === deliveryPointId) {
                        return cc;
                    }
                }
            }
        }
    }
    return false;
}

export function formatNoticePeriod(document) {
    if((document.type!=='smlouva' && document.type!=='podepsana_smlouva') || document.contract_expiration_type === 'unknown') {
        return '-';
    }
    if(!document.notice_period){
        return '-';
    }
    if(document.notice_period_type==='month') {
        return document.notice_period + ' Měsíců';
    }else if(document.notice_period_type==='year') {
        return document.notice_period + ' Let';
    }
    return document.notice_period + ' Dní';
}

export function stringToFloat(value) {
    if(typeof value === "number") {
        return value;
    }
    if(value) {
        value = value.replace(",", ".");
    }
    return parseFloat(value) || 0;
}

export function clientProcessed(client){
    const days = clientDaysToContractEnd(client);
    return daysToProcessed(days);
}

export function clientDaysToContractEnd(client){
    if(!client || !client.is_client){
        return 100000000;
    }
    let min = 100000000;
    client.client_company?.records.forEach(company => {
        if(company.deleted){
            return; //skip deleted ones
        }
        const dpDays = companyProcessedDays(company);
        if(dpDays<min){
            min = dpDays;
        }
    });
    return min;
}

function daysToProcessed(days){
    if(days<=0) {
        return 'not_signed';
    }else if(days<1000){
        return 'expiring';
    }else if(days<10000){
        return 'prolonging';
    }
    return days >= 100000000 ? 'not_client' : 'signed';
}

export function companyProcessed(company){
    const days = companyProcessedDays(company);
    return daysToProcessed(days);
}

export function companyProcessedDays(company){
    let min = 100000000;
    company.delivery_point?.records.forEach(dp => {
        if(dp.deleted){
            return; //skip deleted ones
        }
        const dpDays = deliveryPointDaysToContractEnd(dp);
        if(dpDays<min){
            min = dpDays;
        }
    });
    return min;
}

export function deliveryPointProcessed(dp){
    const days = deliveryPointDaysToContractEnd(dp);
    return daysToProcessed(days);
}

export function processedLoc(processed){
    if(processed==='not_signed'){
        return 'Nepodepsaná smlouva';
    }else if(processed==='expiring'){
        return 'Dobíhající smlouva';
    }else if(processed==='prolonging'){
        return 'Prolongovaná smlouva';
    }else if(processed==='signed'){
        return 'Podepsána nová smlouva';
    }else if(processed==='not_client'){
        return 'Není klient';
    }
    return '-';
}

export function deliveryPointDaysToContractEnd(dp){
    if(!dp){
        return 1000000;
    }
    if(dp.contract_expiration_type==='indefinite'){
        return 1000; //TODO + days to aniversary
    }
    if(dp.contract_expiration){
        var expiration = new Date(dp.contract_expiration);
        var now = new Date();
        const expirationDay = expiration.getTime() / (3600*24*1000);
        const nowDay = now.getTime() / (3600*24*1000);
        const diff = expirationDay - nowDay;
        if(diff>365){
            return diff + 10000;
        }
        return diff;
    }
    return -1;
}