import './App.css';
import React from 'react';
import Header from './Header';
import {
    Client,
    DeliveryPoint,
    ClientDocument,
    Note,
    Company,
    Appointment,
    YearConsumption,
    OfferRecipient
} from "./Client";
import {ClientsList, DeliveryPointsList, OffersList, TaskList, TemplateList} from "./RecordTable";
import LoginForm from './LoginForm';
import Progress from "./Progress";
import BasicTabbedPane from "./TabbedPane";
import {UserSettings} from './Settings';
import {Task, Template} from "./Forms";
import {SecondaryButton} from "./Components";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import AresApp from "./ares";
import * as Importer from './Importer';
import * as Exporter from './Exporter';
import {compareValue, downloadBlob, formatDate, formatNoticePeriod, formatRecordTitle} from "./Functions";
import {KClientContactFields, KCompanyFields, KDeliveryPointFields} from "./RecordFields";
import {GRID_DETAIL_PANEL_TOGGLE_FIELD} from "@mui/x-data-grid-pro";
import {TextField} from "@mui/material";
import {Office} from "./Office";
import {Offers} from "./Offers";
import {ExportDetails} from "./ExportDetails";

let _ = require('lodash');

let progressIdGenerator = 0;
let modalId = 0;

function tryJSONParse(json) {
    if(!json) {
        return json;
    }
    try {
        return JSON.parse(json);
    }catch (e) {
        return false;
    }
}

function compareRecords(r1, r2) {
    const unusedFields = ['modified', 'created', 'modified_access_id', 'created_access_id', 'loaded'];
    for (const [k1, v1] of Object.entries(r1)) {
        if(unusedFields.includes(k1)) {
            continue;
        }
        //null like undefined
        if((v1===null || v1===undefined) && (r2[k1]===null || r2[k1]===undefined)) {
            continue;
        }
        if(!_.isEqual(r2[k1], v1)) {
            return false;
        }
    }
    //now check if fields not contained in first
    for (const [k2, v2] of Object.entries(r2)) {
        if(unusedFields.includes(k2) || r1.hasOwnProperty(k2)) {
            continue;
        }
        if(v2!==null && v2!==undefined) {
            return false;
        }
    }
    return true;
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        pages: [{template: 'home'}],
        pageActions: [],
        clients: [],
        templates: [],
        tasks: [],
        offerRecipients: [],
        loginError: '',
        progresses: {},
        online: false,
        login: tryJSONParse(localStorage.getItem('mn.login')),
        preferences: tryJSONParse(localStorage.getItem('mn.preferences')) || {},
        office: new Office()
    };

    this.showRecord = this.showRecord.bind(this);
    this.back = this.back.bind(this);
    this.newClient = this.newClient.bind(this);
    this.recordChanged = this.recordChanged.bind(this);
    this.recordReset = this.recordReset.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showRecordSelectionModal = this.showRecordSelectionModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getActivePage = this.getActivePage.bind(this);
    this.loadRecords = this.loadRecords.bind(this);
    this.fetchJson = this.fetchJson.bind(this);
    this.fetchApi = this.fetchApi.bind(this);
    this.doFetch = this.doFetch.bind(this);
    this.login = this.login.bind(this);
    this.openPage = this.openPage.bind(this);
    this.startProgress = this.startProgress.bind(this);
    this.finishProgress = this.finishProgress.bind(this);
    this.newDeliveryPoint = this.newDeliveryPoint.bind(this);
    this.newDeliveryPointDocument = this.newDeliveryPointDocument.bind(this);
    this.newMassDeliveryPointDocument = this.newMassDeliveryPointDocument.bind(this);
    this.newTemplate = this.newTemplate.bind(this);
    this.newDocument = this.newDocument.bind(this);
    this.addDeliveryPoints = this.addDeliveryPoints.bind(this);
    this.removeDeliveryPoints = this.removeDeliveryPoints.bind(this);
    this.newNote = this.newNote.bind(this);
    this.newCompany = this.newCompany.bind(this);
    this.newAppointment = this.newAppointment.bind(this);
    this.exportClient = this.exportClient.bind(this);
    this.importFile = this.importFile.bind(this);
    this.importClients = this.importClients.bind(this);
    this.importClientsWrapper = this.importClientsWrapper.bind(this);
    this.exportRecords = this.exportRecords.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.sendExport = this.sendExport.bind(this);
    this.expandRecordForExport = this.expandRecordForExport.bind(this);
    this.getActiveRecordParents = this.getActiveRecordParents.bind(this);
    this.newTask = this.newTask.bind(this);
    this.newOfferRecipient = this.newOfferRecipient.bind(this);
    this.showModalRecord = this.showModalRecord.bind(this);
    this.renderPage = this.renderPage.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteRecords = this.deleteRecords.bind(this);
    this.moveRecord = this.moveRecord.bind(this);
    this.moveRecords = this.moveRecords.bind(this);
    this.restoreRecords = this.restoreRecords.bind(this);
    this.openRecord = this.openRecord.bind(this);
    this.downloadRecord = this.downloadRecord.bind(this);
    this.exportPreviewCurrentRecord = this.exportPreviewCurrentRecord.bind(this);
    this.pageRender = this.pageRender.bind(this);
    this.exportRecordsXLS = this.exportRecordsXLS.bind(this);
    this.logout = this.logout.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.updateActiveRecord = this.updateActiveRecord.bind(this);
    this.updateRecord = this.updateRecord.bind(this);
    this.syncOnlineData = this.syncOnlineData.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.storeRecord = this.storeRecord.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.saveOwnCompany = this.saveOwnCompany.bind(this);
    this.saveOfferEmail = this.saveOfferEmail.bind(this);
    this.savePreferences = this.savePreferences.bind(this);
    this.updateDeliveryPointFromLastSignedAccord = this.updateDeliveryPointFromLastSignedAccord.bind(this);
    this.updateContractExpirations = this.updateContractExpirations.bind(this);
  }

    componentDidMount() {
        AresApp.initApi(this.props.apiUrl);
        const u = new URL(window.location);
        const authToken = u.searchParams.get('code');
        let init;
        if (authToken) {
            const authService = u.pathname.split('/').pop();
            init = fetch(this.props.apiUrl + authService+'/token?code=' + authToken, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    "X-Access-Token": (this.state.login && this.state.login.token) || ''
                },
            }).then((res) => {
                const path = u.pathname.replace('/office', '').replace('/onedrive');

                window.history.replaceState({}, '', u.origin + path);
                if(authService==='onedrive') {
                    let login = {...this.state.login};
                    login.onedrive_logged = true;
                    this.setState({login: login});
                }
                this.syncOnlineData();
            }).catch((err) => {
            });
        }else {
            init = Promise.resolve();
        }
        init.then(() => {
            this.syncOnlineData();
        }).catch((error)=>this.showModal({
            title: 'Chyba inicializace',
            body: error
        }));
    }


    updateStatus() {
        if(this.updateTimer) {
          clearTimeout(this.updateTimer);
        }
        this.fetchJson('status').then((status) => {
            if (status.login) {
                this.setState({
                    login: status.login,
                    loginError: '',
                    office: new Office(status.login.office_token)
                });
                localStorage.setItem('mn.login', JSON.stringify(status.login));
                this.updateTimer = setTimeout(this.updateStatus, 300*1000); //Every 5 minutes
            }
        }, (err) => {
            if(err.code!==403) {
                this.updateTimer = setTimeout(this.updateStatus, 5000); //Try again in 5seconds
            }
        });
    }

    syncOnlineData() {
        this.updateStatus();

        let loadClients = this.loadRecords('client')
            .then((clients) => {
                clients.forEach((record) => this.computeMinMax(record));
                this.setState({
                    'clients': clients
                });
            });
        let loadTemplates = this.loadRecords('template')
            .then((templates) => {
                templates.forEach((record) => this.computeMinMax(record));
                this.setState({
                    'templates': templates
                });
            });
        let loadTasks = this.loadRecords('task')
            .then((tasks) => {
                tasks.forEach((record) => this.computeMinMax(record));
                this.setState({
                      'task': tasks
                });
            });
        let loadOfferRecipients = this.loadRecords('offer_recipient')
            .then((recipients) => {
                this.setState({
                    'offerRecipients': recipients
                });
            });
        let loadPreferences = this.loadRecords('preferences')
            .then((preferences) => {
                this.setState({preferences: preferences});
                localStorage.setItem('mn.preferences', JSON.stringify(preferences));
                return true;
            });
        Promise.all([loadClients, loadTemplates, loadTasks, loadOfferRecipients, loadPreferences])
            .then(() => this.setState({online: true}/*, () => {
                return this.updateContractExpirations();
            }*/))
            .catch((err) => {
                //connectivity failed
                this.setState({online: false});
            });
    }

    updateContractExpirations() {
        let updates = [];
        this.state.clients.forEach(client => {
            if(!client.client_company || !client.client_company.records) {
                return;
            }
            client.client_company.records.forEach(company => {
                if(!company.delivery_point || !company.delivery_point.records) {
                    return;
                }
                company.delivery_point.records.forEach(dp => {
                    if(!dp.delivery_point_document || !dp.delivery_point_document.records) {
                        return;
                    }
                    updates.push(this.updateDeliveryPointFromLastSignedAccord(dp, [{
                        recordType: 'client',
                        id: client.id
                    }, {
                        recordType: 'client_company',
                        id: company.id
                    }], false));
                });
            });
        });
        return Promise.all(updates);
    }

  loadRecords(type) {
      return this.fetchJson(type);
  }

  fetchApi(type, idData, onBackground) {
      let fetchParams = {
          cache: 'no-cache',
          headers: {
              "X-Access-Token": (this.state.login && this.state.login.token) || ''
          }
      };
      if(!isNaN(idData)) {
          type+='/'+idData;
      } else if(idData){
          let data = {...idData};
          fetchParams.method = 'POST';
          let body = new FormData();
          //extract files from data
          for(const p in data) {
              if(data[p] && data[p].data) {
                  //is file
                  body.set(p, new Blob([data[p].data]));
                  data[p] = {...data[p]}; //clone file metadata
                  delete data[p].data;
              }
          }
          body.set('record', JSON.stringify(data));
          fetchParams.body = body;
      }
      const p = this.startProgress(isNaN(idData) && idData ? 'Ukládám změny' : 'Načítám data', onBackground);
      return this.doFetch(this.props.apiUrl + type, fetchParams, p,1);
  }

  doFetch(url, params, p, attempt) {
      return fetch(url, params)
          .then(response => {
              if(!response.ok) {
                  return response.text().then(text =>{
                      const err = {
                          message: response.statusText,
                          code: response.status
                      };
                      try{
                          const jsonRes = JSON.parse(text);
                          if(jsonRes && jsonRes.error){
                              err.message = jsonRes.error;
                          }
                      }catch (e){}
                      throw err;
                  })
              }
              this.finishProgress(p);
              return response;
          }).catch(error => {
              if(error && error.message==='Failed to fetch'){
                  this.setState({online: false});
                  return Promise.reject({message: 'Chyba připojení k internetu'});
              }else if(error.code > 500 && error.code<600 && attempt<5) {
                  //try again
                  return this.doFetch(url, params, p, attempt+1);
              }
              this.finishProgress(p);
              //throw error;
              return Promise.reject(error);
          });
  }

  fetchJson(type, idData, onBackground) {
      return this.fetchApi(type, idData, onBackground)
          .then( response => {
              return response.text().then(text => {
                  let json;
                  try {
                      json = JSON.parse(text);
                  } catch (err) {
                      return Promise.reject({
                          code: 500,
                          message: <div dangerouslySetInnerHTML={{__html: (text)}}/>
                      });
                  }
                  return json;
              });
          }, err => {
              if (err.code === 403) {
                  //login required
                  this.logout(err.message || 'Neznámá chyba');
              }
              return Promise.reject(err);
          });
  }

  findById(list, id) {
      if(typeof id === 'string'){
          id = parseInt(id);
      }
      for(let i=0; i<list.length; i++) {
          let listId = list[i].id;
          if(typeof listId==='string'){
              listId = parseInt(listId);
          }
          if(listId===id) {
              return list[i];
          }
      }
      return null;
  }

  findByExact(list, props) {
      if(!list) {
          return null;
      }
      if(!Array.isArray(list) && list.records) {
          list = list.records;
      }
      for(let i=0; i<list.length; i++) {
          let allMatch = true;
          for (const prop in props) {
              if(compareValue(list[i][prop]) !== compareValue(props[prop])) {
                  allMatch = false;
                  break;
              }
          }
          if(allMatch) {
              return list[i];
          }
      }
      return null;
  }

  replaceById(list, record, oldId, updatePrevious) {
      if(!oldId) {
          oldId = record.id;
      }
      for(let i=0; i<list.length; i++) {
          if(list[i].id===oldId) {
              if(updatePrevious) {
                  list[i] = {...list[i], ...record};
              }else {
                  list[i] = record;
              }
              return i;
          }
      }
      //get new "unstored" id
      if(!record.id) {
          record.id = localStorage.getItem('mn.idGenerator') || -1;
          localStorage.setItem('mn.idGenerator', (record.id -1).toString());
      }
      list.push(record);
      return list.length-1;
  }

  logout(message) {
      this.setState({
          login: false,
          pages: [{
              template: 'home'
          }],
          history: [],
          loginError: message
      });
  }

    getTemplateForRecord(type) {
      if(type==='client') {
          return 'client_form';
      }else if(type==='delivery_point') {
          return 'delivery_point_form';
      }else if(type==='client_document') {
          return 'client_document_form';
      }else if(type==='delivery_point_document'){
          return 'delivery_point_document_form';
      }else if(type==='client_note') {
          return 'note';
      }else if(type==='client_company') {
          return 'company_form';
      }else if(type==='client_appointment'){
          return 'appointment_form';
      }else if(type==='template') {
          return 'template';
      }else if(type==='task') {
          return 'task';
      }else if(type==='year_consumption') {
          return 'year_consumption_form';
      }else if(type==='offer_recipient') {
          return 'offer_recipient_form';
      }
      return '';
    }

    openPage(page, oldId) {
        let pages = [...this.state.pages];
        let updated = false;
        for(let i=0; i<this.state.pages.length; i++) {
            let oldPage = this.state.pages[i];
            if (oldPage.template === page.template &&
                (!page.record || !oldPage.record || page.record.id === oldPage.record.id
                    || !oldPage.record.id || oldPage.record.id===oldId)) {
                //already opened
                pages[i] = page;
                updated = true;
                break;
            }
        }
        if(!updated) {
            pages.push(page);
        }
        this.setState({pages: pages});
    }

    showRecord(record, type, title, parents, onSave) {
        const tpl = this.getTemplateForRecord(type);
        this.openPage({
            template: tpl,
            record: record,
            recordType: type,
            title: title || formatRecordTitle(record, type),
            original_record: {...record},
            parents: parents ? parents : this.getActiveRecordParents(type),
            windowed: type!=='client' && type!=='delivery_point',
            onSave: onSave
        });
    }

    showModalRecord(record, type, title, parents) {
        const tpl = this.getTemplateForRecord(type);
        this.openPage({
            template: tpl,
            record: record,
            recordType: type,
            title: title || formatRecordTitle(record, type),
            original_record: {...record},
            parents: parents ? parents : this.getActiveRecordParents(type),
            windowed: true
        });
    }

    deleteRecord(record, type, forcibly) {
        if(!forcibly) {
            return this.showModal({
                title: 'Smazat záznam',
                body: 'Opravdu chcete smazat záznam typu: '+type+'. Smazaný záznam lze obnovit po zobrazení smazaných záznamů',
                buttons: [
                    {text: 'Ano', onClick: () => this.deleteRecord(record, type, true)},
                    {text: 'Ne'}
                ]
            });
        }else {
            let updated = {...record};
            if(type==='delivery_point_document' && Array.isArray(record.delivery_point_id)){
                //unlink document from single delivery point only
                const parents = this.getActiveRecordParents('delivery_point_document');
                const dpId = parents[2].id;
                updated.delivery_point_id = updated.delivery_point_id.filter(e => e !== dpId);
            }else {
                updated.deleted = this.nowMysql();
            }
            this.updateActiveRecord(updated, {deleted: updated.deleted}, type);
        }
    }

    moveRecords(records, type) {
        let list, oldList, recordType, parentRecord;
        let parents = [];
        let clientPage = this.getActivePage('client');
        if(type==='client_company') {
            list = this.state.clients;
            parentRecord = clientPage.record;
            oldList = parentRecord.client_company.records;
            recordType = 'client';
        }else if(type==='delivery_point') {
            list = clientPage.record.client_company.records;
            recordType = 'client_company';
            parentRecord = this.getActiveRecordInList(clientPage.record.client_company);
            oldList = [...parentRecord.delivery_point.records];
            parents.push({
                recordType: 'client',
                id: clientPage.record.id
            });
        }else {
            return Promise.reject('Not supported');
        }
        return this.showRecordSelectionModal(list, recordType).then((targetRecord) => {
            //remove from previous list
            records.forEach((record) => {
                for (let i = 0; i < oldList.length; i++) {
                    if (oldList[i].id === record.id) {
                        oldList.splice(i, 1);
                        break;
                    }
                }
            });
            if(type==='delivery_point') {
                parentRecord.delivery_point.records = oldList;
            }
            parents.push({
                recordType: recordType,
                id: targetRecord.id
            });
            let thiz = this;
            //add to new list
            function processNextRecord() {
                if(records.length>0) {
                    let updatedRecord = {...records.pop()};
                    if(type==='delivery_point') {
                        updatedRecord.client_company_id = targetRecord.id;
                    }else {
                        updatedRecord.customer_id = targetRecord.id;
                    }
                    return thiz.updateRecord(updatedRecord, updatedRecord, type, parents)
                        .then(() => {
                            if(type==='client_company') {
                                //also store previoius company
                                return thiz.storeClient(parentRecord, parentRecord.id).then(processNextRecord);
                            }
                            return processNextRecord();
                        });
                }else {
                    return true;
                }
            }
            return processNextRecord();
        });
    }

    moveRecord(record, type, forcibly) {
      return this.moveRecords([record], type);
    }

    deleteRecords(records, type, forcibly) {
        if(!records || records.length===0) {
            return;
        }
        if(records.length===1) {
            return this.deleteRecord(records[0], type, forcibly);
        }
        if(!forcibly) {
            return this.showModal({
                title: 'Smazat záznamy',
                body: 'Opravdu chcete smazat vybrané záznamy ('+records.length+') typu: '+type+'. Smazané záznamy lze obnovit po zobrazení smazaných záznamů',
                buttons: [
                    {text: 'Ano', onClick: () => this.deleteRecords(records, type, true)},
                    {text: 'Ne'}
                ]
            });
        }else {
            for(const record of records) {
                this.deleteRecord(record, type, forcibly);
            }
        }
    }

    restoreRecords(records, type) {
        if(!records || records.length===0) {
            return;
        }
        for(const record of records) {
            this.fetchApi(type+'/'+record.id, {action: 'restore'})
                .then(() => record.deleted = null)

        }
    }

    openRecord(record, type) {
        var link = 'open://' + record.file;
        document.location.href = link;
    }

    exportPreviewCurrentRecord() {
        const page = this.getActivePage();
        let record = {...page.record};

        //expand records, find parents
        let parents = this.getActiveRecordParents(page.recordType);
        parents[0].record = this.findById(this.state.clients, parents[0].id);
        for(let i=1; i<parents.length; i++) {
            parents[i].record = this.findById(parents[i-1].record[parents[i].recordType].records, parents[i].id);
        }
        //expand records, add properties
        this.expandRecordForExport(record, page.recordType, parents);


        function buildBody(values, prefix) {
            let body = [];
            for(const p in values) {
                if(typeof values[p] === "object") {
                    body.push(...buildBody(values[p], prefix+p+'.'));
                }else {
                    body.push(<div><span>&#123;{prefix}{p}&#125;</span>: <span>{values[p]}</span></div>);
                }
            }
            return body;
        }
        let body = buildBody(record, '');

        this.showModal({
            title: 'Náhled polí pro export',
            body: body
        });
    }

    downloadRecord(record, type) {
      let resp;
        this.fetchApi(type+'/'+record.id, {action: 'download'})
            .then(response => { resp = response; return response.blob()})
            .then(blob => {
                let name = false;
                const header = resp.headers.get("Content-Disposition");
                if(header) {
                    const parts = header.split(';');
                    name = decodeURIComponent(parts[1].split('=')[1].replace(/^"(.*)"$/, '$1'));
                }
                downloadBlob(blob, name);
            }
        ).catch(err => {
            this.showModal({
                title: 'Stažení souboru',
                body: 'Nepodařilo se stáhnout soubor. ' + (err.message || err.error),
                buttons: [
                    {text: 'OK'}
                ]
            });
        });
    }

    back(forcibly) {
      if(this.state.pages.length<=1) {
          return;
      }
      if(!forcibly && this.getActivePage().changed) {
          //current page has edited record permit the change?
          this.showModal({
              title: 'Zrušit změny',
              body: 'Na stránce jsou provedeny neuložené změny, opravdu je chcete zahodit?',
              buttons: [
                  {text: 'Ano', onClick: () => this.back(true)},
                  {text: 'Ne'}
              ]
          });
          return;
      }
        this.setState({
            pages: this.state.pages.slice(0,-1)
        });
    }

    showModal(parameters) {
      if(!parameters.buttons || parameters.buttons.length===0) {
          parameters.buttons = [{text: 'Ok'}];
      }
      parameters.id = modalId++;
      this.setState({modal: parameters});
      return parameters.id;
    }

    showRecordSelectionModal(list, recordType) {
      let thiz = this;
        return new Promise(function (resolve, reject) {
            const options = list.map((record, idx) =>
                <div><SecondaryButton className="btn" key={idx}
                                      onClick={() => {thiz.closeModal(modal); resolve(record)}}>{formatRecordTitle(record, recordType)}</SecondaryButton></div>);
            let modal = thiz.showModal({
                title: 'Vyberte záznam',
                buttons: [{text: 'Zrušit', onClick: () => {reject()}}],
                body: <div>{options}</div>
            });
        });
    }

    getActivePage(ofRecordType) {
      for(let i=this.state.pages.length-1; i>=0; i--) {
          if(!ofRecordType || this.state.pages[i].recordType === ofRecordType) {
              return this.state.pages[i];
          }
      }
      return null;
    }

    closeModal(id) {
        if(this.state.modal && this.state.modal.id===id) {
            this.setState({modal: null});
        }
    }

    newClient() {
        this.showRecord({}, 'client', true);
    }

    newDeliveryPoint(client_company_id) {
        this.showRecord({client_company_id: client_company_id}, 'delivery_point','Nové odběrné místo');
    }

    newDocument() {
        this.showModalRecord({customer_id: this.getActivePage('client').record.id},'client_document','Nový dokument');
    }

    newDeliveryPointDocument(group) {
      const page = this.getActivePage('delivery_point');
        this.showModalRecord({
            delivery_point_id: page.record.id,
            files: typeof group ==='string' && group!=='Nezařazeno' ? group : ''
        }, 'delivery_point_document','Nový dokument');
    }

    addDeliveryPoints(document, usedDeliveryPoints, allDeliveryPoints){
      if(usedDeliveryPoints.length === allDeliveryPoints.length){
          this.showModal({
              title: 'Přidat odběrné místo',
              body: <div>Všechny odbrná místa jsou již přidána</div>
          });
      }else{
          let availableDps = allDeliveryPoints.filter(dp => !usedDeliveryPoints.includes(dp));
          this.showRecordSelectionModal(availableDps, 'delivery_point')
          .then(dp => {
              let updated = {...document};
              updated.delivery_point_id.push(dp.id);
              return this.updateActiveRecord(updated, {delivery_point_id: updated.delivery_point_id}, 'delivery_point_document');
          });
      }
    }

    removeDeliveryPoints(document, deliveryPoints){
        if(deliveryPoints.length===document.delivery_point_id.length){
            //delete document
            return this.deleteRecord(document, 'delivery_point_document');
        }else{
            return this.showModal({
                title: 'Odebrat odběrná místa',
                body: 'Opravdu chcete odebrat odběrná místa z hromadné smlouvy?',
                buttons: [
                    {text: 'Ano', onClick: () => {
                        let updated = {...document};
                        updated.delivery_point_id = updated.delivery_point_id.filter(id => !deliveryPoints.some(dp => dp.id===id));
                        this.updateActiveRecord(updated, {delivery_point_id: updated.delivery_point_id}, 'delivery_point_document');
                    }},
                    {text: 'Ne'}
                ]
            });
        }
    }

    newMassDeliveryPointDocument(records) {
        let ids = records.map(deliveryPoint => deliveryPoint.id);
        this.showModalRecord({delivery_point_id: ids, is_multiple: ids.length>1}, 'delivery_point_document',ids.length>1 ? 'Nový hromadný dokument' : 'Nový dokument');
    }

    newNote() {
        this.showModalRecord({customer_id: this.getActivePage('client').record.id}, 'client_note','Nová poznámka');
    }

    newTemplate() {
        this.showModalRecord({}, 'template','Nový formulář');
    }

    newTask() {
        this.showModalRecord({}, 'task','Nový úkol');
    }

    newOfferRecipient() {
        this.showModalRecord({}, 'offer_recipient', 'Nový partner');
    }

    newCompany() {
        this.showModalRecord({customer_id: this.getActivePage('client').record.id}, 'client_company','Nová společnost');
    }

    newAppointment() {
        this.showModalRecord({customer_id: this.getActivePage('client').record.id}, 'client_appointment','Nová schůzka');
    }

    exportClient() {
        Exporter.exportClientDeliveryPoints(this.getActivePage('client').record)
            .then(exportData => downloadBlob(exportData.blob, exportData.name));

    }

    importFile() {
      const thiz = this;
      this.showModal({
          title: 'Import dat',
          buttons: [{text: 'Zrušit'}],
          body: <input type="file" onChange={(e) => {
              if(e.currentTarget.files.length===0) {
              }else {
                  let file = e.currentTarget.files[0];
                  Importer.processData(file).then((result) => {
                      if (result.status === 'converted') {
                          thiz.showModal({
                              title: 'Import ' + result.format.format.title,
                              buttons: [
                                  {text: 'Importovat', onClick: () => thiz.importClientsWrapper(result.clients, result.format.format.record)},
                                  {text: 'Zrušit'}
                              ],
                              body: 'Načteno ' + result.clients.length + ' záznamů dle šablony: ' + result.format.format.title
                          });
                      } else {
                          //format is not 100% confident
                          const options = result.format.map((format, idx) =>
                              <SecondaryButton className="btn" key={idx} onClick={() => thiz.importClientsWrapper(result.convert(format.format), format.format.record)}>{format.format.title} {(100*format.confidence).toFixed(2)}%</SecondaryButton>);
                          thiz.showModal({
                              title: 'Import ze souboru',
                              buttons: [{text: 'Zrušit'}],
                              body: <div><span>Nelze přesně určit formát vstupních dat. Prosím vyberte jednu z možností.</span><div>{options}</div></div>
                          });
                      }
                  }, (err) => thiz.showModal({
                      title: 'Import dat',
                      buttons: [{text: 'Zrušit'}],
                      body: 'Nepodařilo se zpracovat data: ' + err
                  })
                  );
              }
          }} />
      });
    }

    importClientsWrapper(records, recordType) {
        const thiz = this;
        let filter = new Promise(function (resolve, reject) {
            if (recordType === 'client_company') {
                //Select client to import to
                if (thiz.state.page.record && thiz.state.page.recordType === 'client') {
                    //Use current client
                    let clients = [];
                    const client = thiz.state.page.record;
                    records.forEach(company => {
                        clients.push({
                            id: client.id,
                            name: client.name,
                            surname: client.surname,
                            client_company: {
                                records: [company]
                            }
                        });
                    });
                    resolve(clients);
                } else {
                    thiz.showRecordSelectionModal(thiz.state.clients, 'client').then((client) => {
                        let clients = [];
                        records.forEach(company => {
                            clients.push({
                                id: client.id,
                                name: client.name,
                                surname: client.surname,
                                client_company: {
                                    records: [company]
                                }
                            });
                        });
                        resolve(clients);
                    }, reject);
                }
            }else {
                resolve(records);
            }
        });
        return filter
            .then((records) => thiz.importClients(records))
            .then((report) => {
                let msg = [];
                for (const reportKey in report) {
                    msg.push(<div>{reportKey} {report[reportKey]}</div>);
                }
                thiz.showModal({body: <div><span>Import proběhl úspěšně. Importovaných záznamů:</span>{msg}</div>});
            });
            //.catch((err) => thiz.showModal({body: 'Nepodařilo se importovat všechna data. '+err}));
    }

    importClients(clients, report) {
      if(!report) {
          report = {
              client_company: 0,
              client: 0,
              client_appointment: 0,
              delivery_point: 0
          };
      }
        if(!clients.length) {
            return report; //this.persistRecords(true).then(() => report);
            //return Promise.resolve(report);
        }
        const client = clients[0];
        let existingClient;
        if(client.id) {
            existingClient = this.findById(this.state.clients, client.id);
        }
        if(!existingClient) {
            const clientFind = {
                name: client.name,
                surname: client.surname
            };
            //find existing client by name
            existingClient = this.findByExact(this.state.clients, clientFind);
        }
        let r;
        if(!existingClient) {
            //create new client
            let clientData = {...client};
            delete clientData.client_company;
            delete clientData.client_appointment;
            for(const field of KClientContactFields()) {
                if(field.default && !clientData[field.field]) {
                    clientData[field.field] = field.default({row: clientData});
                }
            }
            r = this.updateRecord(clientData, clientData, 'client', [], true);
        }else {
            r = Promise.resolve(existingClient);
        }
        return r.then((record) => {
            if(!existingClient) {
                report.client++;
            }
            //find client company
            const company = client.client_company.records[0];
            const companyFind = {
                ico: company.ico
            };
            const existingCompany = this.findByExact(record.client_company, companyFind);
            if(!existingCompany) {
                let updateCompany = {...company};
                delete updateCompany.delivery_point;
                for(const field of KCompanyFields) {
                    if(field.default && !updateCompany[field.field]) {
                        updateCompany[field.field] = field.default({row: updateCompany});
                    }
                }
                updateCompany['customer_id'] = record.id;
                r = this.updateRecord(updateCompany, updateCompany, 'client_company', [{recordType: 'client', id: record.id}], true);
            }else {
                r = Promise.resolve(existingCompany);
            }
            return r.then((clientCompany) => {
                if(!existingCompany) {
                    report.client_company++;
                }
                const nowISO = (new Date()).toISOString();
                let updates = [];
                if(company.delivery_point && company.delivery_point.records) {
                    company.delivery_point.records.forEach((delivery_point) => {
                        let existingDP = null;
                        if(delivery_point.ean) {
                            const dpFind = {
                                ean: delivery_point.ean
                            };
                            existingDP = this.findByExact(clientCompany.delivery_point, dpFind);
                        }
                        if(!existingDP) {
                            for(const field of KDeliveryPointFields()) {
                                if(field.default && !delivery_point[field.field]) {
                                    delivery_point[field.field] = field.default({row: delivery_point});
                                }
                            }
                            delivery_point.client_company_id = clientCompany.id;
                            r = this.updateRecord(delivery_point, delivery_point, 'delivery_point', [{recordType: 'client', id: record.id}, {recordType: 'client_company', id: clientCompany.id}], true);
                            updates.push(r.then(() => {
                                report.delivery_point++;
                            }));
                        }else {
                            //TODO update some fields only?
                        }
                    });
                }
                //find appointment
                if(!client.client_appointment || client.client_appointment.records.length===0 ||
                    client.client_appointment.records[0].date<nowISO) {
                    //Client has no appointments or appointment is in the past
                    //return Promise.resolve(report);
                }else{
                    const appointment = client.client_appointment.records[0];
                    let existingAppointment = null;
                    if(record.client_appointment && record.client_appointment.records) {
                        for (const ca of record.client_appointment.records) {
                            if(ca.date>nowISO && ca.is_imported && !ca.deleted) {
                                existingAppointment = ca;
                                break;
                            }
                        }
                    }
                    //const existingAppointment = this.findByExact(record.client_appointment, appointmentFind);
                    if (existingAppointment) {
                        //TODO compare if changed
                        appointment.id = existingAppointment.id;
                    }else {
                        appointment.customer_id = record.id;
                    }
                    r = this.updateRecord(appointment, appointment, 'client_appointment', [{
                        recordType: 'client',
                        id: record.id
                    }], true);
                    updates.push(r.then(() => {
                        if (!existingAppointment) {
                            report.client_appointment++;
                        }
                    }));
                }
                return Promise.all(updates).then(() => Promise.resolve(report));
            });
            //Continue with next client
        }).then((report) => this.importClients(clients.slice(1), report));
    }

    recordChanged(record) {
      let page = this.getActivePage();
      page.record = record;
      page.changed = !compareRecords(record, page.original_record);
      this.openPage(page);
    }
    recordReset() {
        let page = this.getActivePage();
        page.record = {...page.original_record};
        page.changed = false;
        this.openPage(page);
    }
    startProgress(title, isBackground) {
      const id = progressIdGenerator++;
      this.setState((prevState, props) => ({
          progresses: Object.assign(prevState.progresses, {[id]:{title: title, background: !!isBackground}})
      }));
        return id;
    }
    finishProgress(progress) {
        this.setState((prevState, props) => {
            let np = {...this.state.progresses};
            delete np[progress];
            return {progresses: np}
        });
    }
    nowMysql() {
        const now = new Date().toISOString();
        return now.substring(0, 10) + ' ' + now.substring(11, 19);
    }
    getActiveRecordParents(type) {
        let parents = [];
        for(let i=0; i<this.state.pages.length; i++) {
            let page = this.state.pages[i];
            if(page.parents) {
                //replace parents from opened windows with the specific one
                //Handles case when subrecord is opned from standalone record
                parents = [...page.parents];
            }
            if(page.recordType===type) {
                break;
            }
            if(!page.record) {
                //skip non record pages
                continue;
            }
            parents.push({
                recordType: page.recordType,
                id: page.record.id
            });

            if(type==='delivery_point' || type==='delivery_point_document') {
                if(page.record && page.record.client_company) {
                    parents.push({
                        recordType: 'client_company',
                        id: this.getActiveRecordInList(page.record.client_company).id
                    });
                }
            }
        }
        return parents;
    }
    saveUser(userData, changes) {
        return this.fetchJson('user', userData);
    }
    saveOwnCompany(company, changes) {
        let newPreferences = {...this.state.preferences};
        newPreferences.company = {...company};
        return this.savePreferences(newPreferences);
    }
    saveOfferEmail(offerEmail, changes) {
        let newPreferences = {...this.state.preferences};
        newPreferences.offer_email = {...offerEmail};
        return this.savePreferences(newPreferences);
    }
    savePreferences(preferences) {
        return this.fetchJson('preferences', preferences)
            .then(newPreferences => {
                this.setState({preferences: newPreferences});
                localStorage.setItem('mn.preferences', JSON.stringify(newPreferences));
            });
    }
    updateActiveRecord(updatedRecord, changes, type) {
        const page = this.getActivePage();
        if(page.onSave) {
            let r = page.onSave(updatedRecord, changes, type);
            if(!(r instanceof Promise)) {
                r = Promise.resolve(r);
            }
            let pages = [...this.state.pages];
            pages.splice(pages.length-1, 1);
            return r.then(() => this.setState({
                pages: pages
            }));
        }
        if(!type) {
            type = page.recordType;
        }
        //get parents path
        const parents = this.getActiveRecordParents(type);
        /*if(type==='year_consumption') {
            let app = this;
            //set consumption to the parent
            let parentPageIdx = this.getCurrentPageIndex(parents[parents.length-1]);
            parents.splice(parents.length-1, 1);
            let recordCopy = {...this.state.pages[parentPageIdx].record};
            recordCopy.year_consumption= updatedRecord;
            let pages = [...this.state.pages];
            pages[parentPageIdx].record = recordCopy;
            return new Promise(function (resolve) {
                app.setState({
                    pages: pages,
                    modal: null
                }, () => resolve());
            });
        }*/
        return this.updateRecord(updatedRecord, changes, type, parents);
    }
    updateRecord(updatedRecord, changes, type, parents){
        const isNew = !updatedRecord.id;
        const changed = isNew || Object.keys(changes).length>0;

        let app = this;
        if(!changed) {
            return true;
        }
        if(isNew){
            changes = updatedRecord;
        }else {
            for (const prop in updatedRecord) {
                if (prop === 'id' || prop.endsWith('_id')) {
                    changes[prop] = updatedRecord[prop];
                }
            }
        }
        return this.fetchJson(type, changes, false)
            .catch((err) => {
                return new Promise(function (resolve, reject) {
                    app.showModal({
                        title: 'Chyba ukládání',
                        body: err.error || err.message,
                        buttons: [
                            {
                                text: 'Opakovat', onClick: () => {
                                    app.updateRecord(updatedRecord, changes, type, parents).then(resolve, reject);
                                }
                            },
                            {
                                text: 'Smazat změnu', onClick: () => {
                                    reject(err.error || err.message);
                                }
                            }
                        ]
                    });
                });
            }).then((record) => {
                return this.storeRecord(parents, record, type, updatedRecord.id);
            }).then((updatedRecord) => {
                let currentPage = app.getActivePage();
                if (type === currentPage.recordType && (
                    (isNew && (!currentPage.record || !currentPage.record.id || currentPage.record.id < 0))
                    || (!isNew && currentPage.record.id === updatedRecord.id))) {

                    if (currentPage.windowed && isNew && type !== 'delivery_point') {
                        //close
                        this.back(true);
                    } else {
                        //refresh
                        currentPage = {...currentPage};
                        currentPage.record = updatedRecord;
                        this.openPage(currentPage);
                    }
                }
                if ((isNew || Array.isArray(changes.delivery_point_id)) && type === 'delivery_point_document' && updatedRecord.type === 'podepsana_smlouva') {
                    let client = this.getActivePage('client').record;
                    let company = this.findById(client.client_company.records, parents[1].id);
                    if(company) {
                        const dpIds = Array.isArray(updatedRecord.delivery_point_id) ? updatedRecord.delivery_point_id : [updatedRecord.delivery_point_id];
                        let dpParents = [...parents];
                        while(dpParents.length>2) {
                            dpParents.pop(); //remove self
                        }
                        return Promise.all(dpIds.map((dpId) => {
                            const dp = this.findById(company.delivery_point.records, dpId);
                            return app.updateDeliveryPointFromLastSignedAccord(dp, dpParents, true);
                        }))
                        .then(() => updatedRecord);
                    }
                    /*let p = this.getActivePage('delivery_point');
                    if (p) {
                        let dpParents = [...parents];
                        dpParents.pop(); //remove self
                        return app.updateDeliveryPointFromLastSignedAccord(p.record, dpParents)
                            .then(() => updatedRecord);
                    }*/
                }
                return updatedRecord;
            });
    }

    updateSubRecords(recordCollection, subRecord, oldId, setAsActiveRecord) {
        recordCollection = {
            records: recordCollection ? [...recordCollection.records] : [],
            active_record: (recordCollection && recordCollection.active_record) || 0
        };
        let idx = this.replaceById(recordCollection.records, subRecord, oldId, true);
        subRecord = recordCollection.records[idx];
        if(setAsActiveRecord) {
            idx = this.convertListIndexToActiveOnly(recordCollection.records, idx);
            recordCollection.active_record = idx;
        }
        return recordCollection;
    }

    updateDeliveryPointFromLastSignedAccord(deliveryPoint, parents, newAccord) {
        let now = new Date();
        let currentAccord = null;
        /*for (const doc of deliveryPoint.delivery_point_document.records) {
            if(doc.deleted) {
                continue;
            }
            if(doc.type==='podepsana_smlouva') {
                if(doc.contract_expiration_type==='indefinite') {
                    currentAccord = doc;
                    break;
                }
                if(doc.contract_expiration_type==='date' && doc.contract_expiration>now) {
                    currentAccord = doc;
                    now = doc.contract_expiration;
                }
            }
        }*/
        for (const doc of deliveryPoint.delivery_point_document.records.slice().reverse()) {
            if(doc.deleted) {
                continue;
            }
            if(doc.type==='podepsana_smlouva') {
                currentAccord = doc;
                break;
            }
        }
        if(!currentAccord) {
            return Promise.resolve(deliveryPoint);
        }
        let documentUpdate;
        if(currentAccord.accord_type==='S prolongací' && currentAccord.contract_expiration_type==='date' && !newAccord) {
            if(new Date(currentAccord.contract_expiration)<now) {
                let nextExpiration = new Date(currentAccord.contract_expiration);
                nextExpiration.setFullYear(nextExpiration.getFullYear()+1);
                documentUpdate = this.updateRecord(currentAccord, {
                    contract_expiration: nextExpiration
                }, 'delivery_point_document', [...parents, {recordType: 'delivery_point', id: deliveryPoint.id}]);
            }
        }
        if(newAccord || documentUpdate) {
            if(!documentUpdate) {
                documentUpdate = Promise.resolve(currentAccord);
            }
            //Set fields from current signed accord
            return documentUpdate.then(updatedAccord => {
                const newNoticePeriod = formatNoticePeriod(updatedAccord);
                return this.updateRecord(deliveryPoint, {
                    notice_period: newNoticePeriod,
                    contract_expiration: updatedAccord.contract_expiration,
                    supplier: updatedAccord.supplier
                }, 'delivery_point', parents);
            });
        }
        return Promise.resolve(deliveryPoint);
    }

    computeMinMax(record) {
      //const nowISO = (new Date()).toISOString();
      let combinedMinMaxes = {};
        for (const recordKey in record) {
            if(!record[recordKey] || !record[recordKey].records) {
                continue;
            }
            let minMaxes = {};
            for (let childRec of record[recordKey].records) {
                if(childRec.deleted) {
                    //skip deleted records
                    continue;
                }
                if(childRec.is_completed) {
                    //skip completed records
                    continue;
                }
                let childMinMaxes = this.computeMinMax(childRec);
                for(const prop in childRec) {
                    if(prop==='id' || prop.endsWith('_id')) {
                        continue;
                    }
                    const propVal = childRec[prop];
                    /*if(typeof propVal === 'string' && propVal.length===24 && propVal.at(23)==='Z') {
                        if(propVal<nowISO) {
                            continue;
                        }
                    }*/
                    if(!minMaxes[prop]) {
                        minMaxes[prop] = {
                            min: propVal,
                            max: propVal,
                            min_id: childRec.id,
                            max_id: childRec.id
                        };
                    }else {
                        if(minMaxes[prop].min > propVal) {
                            minMaxes[prop].min = propVal;
                            minMaxes[prop].min_id = childRec.id
                        }
                        if(minMaxes[prop].max < propVal) {
                            minMaxes[prop].max = propVal;
                            minMaxes[prop].max_id = childRec.id
                        }
                    }
                }
                for(const prop in childMinMaxes) {
                    if(!minMaxes[prop]) {
                        minMaxes[prop] = childMinMaxes[prop];
                    }else {
                        if(minMaxes[prop].min > childMinMaxes[prop].min) {
                            minMaxes[prop].min = childMinMaxes[prop].min;
                            minMaxes[prop].min_id = childMinMaxes[prop].min_id;
                        }
                        if(minMaxes[prop].max < childMinMaxes[prop].max) {
                            minMaxes[prop].max = childMinMaxes[prop].max;
                            minMaxes[prop].max_id = childMinMaxes[prop].max_id;
                        }
                    }
                }
            }
            for(let prop in minMaxes) {
                combinedMinMaxes[recordKey+'.'+prop] = minMaxes[prop];
            }
            minMaxes.records = record[recordKey].records;
            minMaxes.active_record = record[recordKey].active_record || 0;
            record[recordKey] = minMaxes;
        }
        return combinedMinMaxes;
    }

    storeClient(record, oldId, updatePrevious) {
        let newClients = [...this.state.clients];
        let idx = this.replaceById(newClients, record, oldId, updatePrevious);
        record = newClients[idx];
        this.computeMinMax(record);
        this.setState({clients: newClients});
        return record;
    }

    getCurrentPageIndex(parent) {
      for(let i=this.state.pages.length-1; i>=0; i--) {
          const page = this.state.pages[i];
          if(page.recordType === parent.recordType &&
              page.record.id === parent.id) {
              return i;
          }
      }
      return false;
    }

    convertListIndexToActiveOnly(recordSet, index) {
      let ret = index;
        for(let i=0; i<index; i++) {
            if(recordSet[i].deleted) {
                ret--;
            }
        }
        return ret;
    }

    getActiveRecordInList(recordSet, useDeleted) {
        let idx = recordSet.active_record || 0;
        if(!useDeleted) {
            //skip deleted
            for(let i=0; i<recordSet.records.length; i++) {
                if(recordSet.records[i].deleted) {
                    continue;
                }
                if(idx-- === 0) {
                    return recordSet.records[i];
                }
            }
            return null;
        }
        return recordSet.records[idx];
    }

    storeRecord(parents, recordChanges, type, id, updateViews) {
        let r;
        let updateStack = [];
        if (type === 'client') {
            //update client record in the list
            if(updateViews) {
                this.setState({currentPage: 0});
            }
            let newClient = this.storeClient(recordChanges, id, true);
            updateStack.push({
                record: newClient,
                recordType: 'client'
            });
            recordChanges = newClient;
        } else if (type==='template') {
            let newTemplates = [...this.state.templates];
            let idx = this.replaceById(newTemplates, recordChanges, id, true);
            recordChanges = newTemplates[idx];
            this.setState({
                templates: newTemplates,
                currentPage: updateViews ? 1 : this.state.currentPage
            });
        }  else if (type==='task') {
            let newTasks = [...this.state.tasks];
            let idx = this.replaceById(newTasks, recordChanges, id, true);
            recordChanges = newTasks[idx];
            this.setState({
                tasks: newTasks,
                currentPage: updateViews ? 2 : this.state.currentPage
            });
        } else if(type==='offer_recipient'){
            let newRecipients = [...this.state.offerRecipients];
            let idx = this.replaceById(newRecipients, recordChanges, id, true);
            recordChanges = newRecipients[idx];
            this.setState({
                offerRecipients: newRecipients,
                currentPage: updateViews ? 4 : this.state.currentPage
            });
        } else if (type==='client_company') {
            let client = this.findById(this.state.clients, parents[0].id);
            client.client_company = this.updateSubRecords(client.client_company, recordChanges, id, updateViews);
            r = this.storeClient(client);
            updateStack.push({
                record: client,
                recordType: 'client'
            });
            //recordChanges = this.getActiveRecordInList(r.client_company);
        } else if(type==='client_note') {
            let client = this.findById(this.state.clients, parents[0].id);
            client.customer_note = this.updateSubRecords(client.customer_note, recordChanges, id, updateViews);
            updateStack.push({
                record: client,
                recordType: 'client',
                currentPage: updateViews ? 2 : undefined
            });
            r = this.storeClient(client);
            //recordChanges = r.customer_note.records[r.customer_note.active_record];
        } else if(type==='client_appointment') {
            let client = this.findById(this.state.clients, parents[0].id);
            client.client_appointment = this.updateSubRecords(client.client_appointment, recordChanges, id, updateViews);
            updateStack.push({
                record: client,
                recordType: 'client',
                currentPage: updateViews ? 3 : undefined
            });
            r = this.storeClient(client);
            //recordChanges = r.client_appointment.records[r.client_appointment.active_record];
        } else if(type==='client_document') {
            let client = this.findById(this.state.clients, parents[0].id);
            client.document = this.updateSubRecords(client.document, recordChanges, id, updateViews);
            updateStack.push({
                record: client,
                recordType: 'client',
                currentPage: updateViews ? 1 : undefined
            });
            r = this.storeClient(client);
            //recordChanges = r.document.records[r.document.active_record];
        } else if(type==='delivery_point') {
            let client = this.findById(this.state.clients, parents[0].id);
            let company = this.findById(client.client_company.records, parents[1].id);
            company.delivery_point = this.updateSubRecords(company.delivery_point, recordChanges, id, updateViews);
            client.client_company = this.updateSubRecords(client.client_company, company, company.id, updateViews);
            updateStack.push({
                record: client,
                recordType: 'client',
                currentPage: updateViews ? 0 : undefined
            });
            updateStack.push({
                record: company,
                recordType: 'client_company'
            });
            r = this.storeClient(client);
            //recordChanges = company.delivery_point.records[company.delivery_point.active_record];
        }else if(type==='delivery_point_document') {
            let client = this.findById(this.state.clients, parents[0].id);
            let company = this.findById(client.client_company.records, parents[1].id);
            let dp;
            if(parents[2] && !Array.isArray(recordChanges.delivery_point_id)) {
                dp = this.findById(company.delivery_point.records, parents[2].id);
                dp.delivery_point_document = this.updateSubRecords(dp.delivery_point_document, recordChanges, id, updateViews);
                company.delivery_point = this.updateSubRecords(company.delivery_point, dp, dp.id, updateViews);
            }else {
                recordChanges.delivery_point_id.forEach(dpId => {
                    dp = this.findById(company.delivery_point.records, dpId);
                    dp.delivery_point_document = this.updateSubRecords(dp.delivery_point_document, recordChanges, id, updateViews);
                    company.delivery_point = this.updateSubRecords(company.delivery_point, dp, dp.id, updateViews);
                });
                //handle removal
                company.delivery_point.records.forEach(dp => {
                    if(recordChanges.delivery_point_id.includes(dp.id)){
                        return;
                    }
                    dp.delivery_point_document.records = dp.delivery_point_document.records.filter(doc => doc.id!==id);
                });
                dp = null;
            }
            client.client_company = this.updateSubRecords(client.client_company, company, company.id, updateViews);
            updateStack.push({
                record: client,
                recordType: 'client',
                currentPage: updateViews ? 0 : undefined
            });
            updateStack.push({
                record: company,
                recordType: 'client_company'
            });
            if(dp) {
                updateStack.push({
                    record: dp,
                    recordType: 'delivery_point'
                });
            }
            r = this.storeClient(client);
            //recordChanges = dp ? dp.delivery_point_document.records[dp.delivery_point_document.active_record] : recordChanges;
        }
        updateStack.forEach((entry) => {
            let idx = this.getCurrentPageIndex({
                recordType: entry.recordType,
                id: entry.record.id
            });
            if(idx===false){
                return;
            }
            let page = {...this.state.pages[idx]};
            page.record = entry.record;
            if(entry.currentPage!==undefined) {
                page.currentPage = entry.currentPage;
            }
            this.openPage(page, entry.record.id);
        });
        return recordChanges;
    }
    cleanRecord(record) {
        let ret = {};
        for (const prop of record) {
            if(prop[0]==='_') {
                continue;
            }
            if(record[prop] && record[prop].records) {
                continue;
            }
            ret[prop] = record[prop];
        }
        return ret;
    }

    removeChangesFromList(changes, newChangesList) {
        for(let i=0; i<newChangesList.length; i++) {
            if(newChangesList[i].changeId===changes.changeId) {
                //remove stored changes from the list
                newChangesList.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    login(username, password) {
      const p = this.startProgress('Probíhá přihlášení');
      let thiz = this;
        fetch(this.props.apiUrl+'login', {
            method: 'POST',
            //credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
            .then(response => response.json())
            .then(function (loginData) {
                thiz.finishProgress(p);
                if(loginData.token) {
                    thiz.setState({
                        login: loginData,
                        loginError: ''
                    });
                    localStorage.setItem('mn.login', JSON.stringify(loginData));
                    thiz.syncOnlineData();
                }else {
                    thiz.setState({
                        loginError: loginData.message || loginData.error
                    });
                }
            })
            .catch((error) => {
                thiz.finishProgress(p);
                thiz.setState({
                    loginError: error
                });
            });
    }
    pageRender(page) {
      document.title = page.title || 'MN';
    }

    exportRecordsXLS(records, type){
        let expandedRecords = [];
        let columns = {};
        records.forEach((record) => {
            let r = {...record}
            columns = this.expandRecordForExport(r, type, []);
            expandedRecords.push(r);
        });
        Exporter.exportDataRaw(expandedRecords, columns).then(blob => downloadBlob(blob, 'Export.xlsx'));
    }
    exportRecords(records, type) {
      const thiz = this;
      if(type==='delivery_point') {
          //get template
          if(this.state.preferences.offer_email && this.state.preferences.offer_email.attachment) {
              const template = this.findById(this.state.templates, this.state.preferences.offer_email.attachment);
              if(template) {
                  return this.exportTemplate(template, records, type);
              }
          }
          this.showModal({
                  title: 'Cenová nabídka',
                  buttons: [{text: 'Zrušit'}],
                  body: <div><span>Není nastaven formulář pro vytváření cenové nabídky.</span></div>
              });
      }else {
          const options = this.state.templates.map((template, idx) =>
              !template.fill_template ? null :
                  <SecondaryButton className="btn" key={idx} onClick={() =>
                      thiz.exportTemplate(template, records, type)
                          .then(() => thiz.closeModal(modal))}>{template.name}</SecondaryButton>);
          let modal = thiz.showModal({
              title: 'Export do šablony',
              buttons: [{text: 'Zrušit'}],
              body: <div><span>Prosím vyberte jeden z definovaných exportů.</span>{options}</div>
          });
      }
    }

    getRecordTypeFields(type) {
        if(type==='client') {
            return KClientContactFields();
        }else if(type==='client_company') {
            return KCompanyFields;
        }else if(type==='delivery_point') {
            return KDeliveryPointFields();
        }
    }

    expandRecordForExport(record, type, parents) {
        const row = {...record};
        const fields = this.getRecordTypeFields(type);
        const today = new Date();
        record.offer_valid_from = formatDate(today);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate()+1);
        record.offer_valid_to = formatDate(tomorrow);

        const columnsMapping = {};

        for(const field of fields) {
            if(field.field==='action' || field.field === GRID_DETAIL_PANEL_TOGGLE_FIELD) {
                continue;
            }
            if(field.headerName) {
                columnsMapping[field.field] = field.headerName;
            }
            const fieldParams = {
                value: row[field.field],
                row: row,
                api: {
                    getRow: () => row
                }
            }
            let value = field.default?.(fieldParams) || row[field.field];
            fieldParams.value = value;
            if(field.valueFormatter){
                value = field.valueFormatter(fieldParams);
            }else if(field.valueGetter){
                value = field.valueGetter(fieldParams);
            }
            record[field.field] = value!==undefined ? value : '';
        }
        for(const parent of parents){
            for(const prop in parent.record) {
                const value = parent.record[prop];
                if(value===null || value.records) { //Skip sub records
                    continue;
                }
                if(!record[parent.recordType]) {
                    record[parent.recordType] = {};
                }
                if(typeof value === "boolean") {
                    record[parent.recordType][prop] = value ? 1 : 0;
                }else {
                    record[parent.recordType][prop] = value;
                }
            }
            const fields = this.getRecordTypeFields(parent.recordType);
            for(const field of fields) {
                if(field.field==='action' || field.field === GRID_DETAIL_PANEL_TOGGLE_FIELD) {
                    continue;
                }
                if(record[field.field]===undefined) {
                    record[field.field] = '';
                }
            }
        }
        if(!record.user) {
            record.user = {};
        }
        for(const prop in this.state.login) {
            if(['name', 'surname', 'role'].indexOf(prop)===-1) {
                continue;
            }
            record.user[prop] = this.state.login[prop];
        }
        if(this.state.preferences.company) {
            record.own_company = {...this.state.preferences.company};
        }
        if(type==='delivery_point') {
            if (!record.contract_expiration || new Date(record.contract_expiration) < today) {
                record.contract_expiration = formatDate(today);
            } else {
                record.contract_expiration = formatDate(record.contract_expiration);
            }
            const fields = KDeliveryPointFields();
            if(!record.year_consumption){
                record.year_consumption = {};
            }
            record.year_consumption.total = fields[7]/*.year_consumption*/.valueFormatter({
                api: {
                    getRow: () => record
                }
            });
        }
        return columnsMapping;
    }

    exportTemplate(template, records, type) {
        const fillTemplate = JSON.parse(template.fill_template);
        if(!fillTemplate) {
            return Promise.reject('Šablona nemá nastavený předpis pro vyplnění');
        }

        //expand records, find parents
        let parents = this.getActiveRecordParents(type);
        parents[0].record = this.findById(this.state.clients, parents[0].id);
        for(let i=1; i<parents.length; i++) {
            parents[i].record = this.findById(parents[i-1].record[parents[i].recordType].records, parents[i].id);
        }
        //expand records, add properties
        for(let record of records){
            this.expandRecordForExport(record, type, parents);
        }

        //get the file
        return this.fetchApi('template/'+template.id, {action: 'download'})
            .then(response => response.blob())
            .then(blob => Exporter.exportData(blob, fillTemplate, records))
            .then(exportData => {
                this.openPage({
                    template: 'process_export',
                    exportData: exportData,
                    title: 'Export dokončen',
                    windowed: false
                });

                /*if(this.state.office.isLogged()) {
                    let email = {
                        recipients: template.send_emails,
                        subject: template.send_subject,
                        body: template.send_body,
                        attachments: [exportData]
                    };
                    let errors = {
                        recipients: false,
                        subject: false,
                        body: false,
                        send: false
                    }
                    return this.sendExport(email, errors);
                }else {
                    return downloadBlob(exportData.blob, exportData.name);
                }*/
            });
    }

    sendExport(email, errors) {
        return this.showModal({
            title: 'Export dokončen',
            body: (<div><p>Vytvořen soubor <SecondaryButton
                onClick={() => downloadBlob(email.attachments[0].blob, email.attachments[0].name)}>{email.attachments[0].name}</SecondaryButton>
            </p>
                <p>Email k odeslani:</p>
                <TextField fullWidth multiline={true}
                           maxRows="3" label={'Prijemce'}
                           helperText={errors.recipients}
                           error={!!errors.recipients}
                           defaultValue={email.recipients}
                           onChange={(e) => email.recipients = e.currentTarget.value}/>
                <br/><br/>
                <TextField fullWidth value={email.subject} label={'Predmet'}
                           helperText={errors.subject}
                           error={!!errors.subject}
                           defaultValue={email.subject}
                           onChange={e => email.subject = e.currentTarget.value}/>
                <br/><br/>
                <TextField fullWidth multiline={true}
                           maxRows="8" label={'Telo'}
                           helperText={errors.body}
                           error={!!errors.body}
                           defaultValue={email.body}
                           onChange={(e) => email.body = e.currentTarget.value}/>
                <p className={'error'}>{errors.send}</p>
            </div>),
            buttons: [
                {text: 'Odeslat', onClick: () => {
                        return this.state.office.sendEmail(email)
                        .catch (e => {
                            return this.sendExport(email, e);
                        });
                    }},
                {text: 'Zavřít'}
            ]
        });
    }

    renderPage(page, inModalWindow) {
        let p = '';
        let pageParams = {
            data: page.record,
            originalData: page.original_record,
            onRecordChanged: this.recordChanged,
            onRecordSave: this.updateActiveRecord,
            onRecordReset: this.recordReset,
            onRecordDetail: this.showRecord,
            onPageRender: this.pageRender,
            onWindowClose: this.back,
            onRecordDelete: this.deleteRecord,
            onRecordMove: this.moveRecord,
            onRecordsDelete: this.deleteRecords,
            onRecordsRestore: this.restoreRecords,
            onRecordsExport: this.exportRecords,
            onRecordsMove: this.moveRecords,
            onRecordOpen: this.openRecord,
            onRecordDownload: this.downloadRecord,
            onExportPreview: this.exportPreviewCurrentRecord,
            asWindow: !!inModalWindow,
            title: page.title
        }
        if(page.template==='home') {
            const clientActions = [<Button key={1} onClick={this.newClient} startIcon={<AddIcon/>}>Nový klient</Button>];
            const templateActions = [<Button key={1} onClick={this.newTemplate} startIcon={<AddIcon/>}>Nová šablona</Button>];
            const taskActions = [<Button key={1} onClick={this.newTask} startIcon={<AddIcon/>}>Nový úkol</Button>];

            p = (<BasicTabbedPane currentTab={this.state.currentPage || 0}
                                  setCurrentTab={(index) => this.setState({currentPage: index})}
                                  tabs={[
                {title: 'Seznam klientů', content: <ClientsList {...pageParams} records={this.state.clients}
                                                                onRecordDetail={this.showRecord}
                                                                actions={clientActions}
                                                                onRecordsExport={this.exportRecordsXLS}/>},
                {title: 'Seznam odběrných míst', content: <DeliveryPointsList {...pageParams} records={this.state.clients}
                                                                              onRecordDetail={this.showRecord}
                                                                              onRecordsExport={this.exportRecordsXLS}
                                                                              onRecordParentDetail={(delivery_point, company, client) => {
                        client.client_company.active_record = company;
                        this.showRecord(client, 'client');
                    }
                    }/>},
                {title: 'Formuláře', content: <TemplateList {...pageParams} records={this.state.templates} onRecordDetail={this.showModalRecord} actions={templateActions} contextActions={[
                    {onClick: (records) => this.deleteRecords(records, 'template'), title: 'Smazat označené'}
                    ]}/>},
                {title: 'Úkoly', content: <TaskList {...pageParams} records={this.state.tasks} onRecordDetail={this.showModalRecord} actions={taskActions} contextActions={[
                    {onClick: (records) => this.deleteRecords(records, 'task'), title: 'Smazat označené'}
                    ]}/>},
                {title: 'Cenové nabídky', content: <Offers {...pageParams} onNewOfferRecipient={this.newOfferRecipient}
                                                           offerRecipients={this.state.offerRecipients}
                                                           onOfferSettingSave={this.saveOfferEmail}
                                                           officeUser={this.state.login.office_user}
                                                           officeLogged={this.state.login.office_logged}
                                                           officeLogin={this.state.login.office_login}
                                                           offerEmail={this.state.preferences.offer_email || {}}
                                                           templates={this.state.templates}
                                                           onOfficeLogout={() => {
                                                               this.fetchApi('office/disconnect').then(() => {
                                                                   const login = {...this.state.login, office_logged: false};
                                                                   this.setState({
                                                                       login: login
                                                                   });
                                                               })
                                                           }}
                                                           onRecordDetail={this.showModalRecord}
                />}
             ]}/>)

        }else if(page.template==='client_form') {
            p = (<Client {...pageParams}
                            onNewDeliveryPoint={this.newDeliveryPoint}
                            onNewDocument={this.newDocument}
                            onNewNote={this.newNote}
                            onNewCompany={this.newCompany}
                            onNewAppointment={this.newAppointment}
                            onNewMassDeliveryPointDocument={this.newMassDeliveryPointDocument}
                             onActiveCompanyChange={(index) => {
                                 let page = this.getActivePage();
                                 page.record.client_company.active_record = index;
                                 this.openPage(page, true);
                             }}
                            currentPage={page.currentPage || 0}
                         setCurrentPage={(index) => {
                             let page = this.getActivePage();
                             page.currentPage = index;
                             this.openPage(page, true);
                         }}
                            />);
        }else if(page.template==='delivery_point_form') {
            p = (<DeliveryPoint {...pageParams}
                    onNewDocument={this.newDeliveryPointDocument}/>)
        }else if(page.template==='client_document_form') {
            p = (<ClientDocument {...pageParams} />)
        }else if(page.template==='delivery_point_document_form'){
            const parents = this.getActiveRecordParents('delivery_point_document');
            const client = this.findById(this.state.clients, parents[0].id);
            const deliveryPoints = this.findById(client.client_company.records, parents[1].id).delivery_point.records;
            p = (<ClientDocument {...pageParams}
                                 allDeliveryPoints={deliveryPoints}
                                 parentDeliveryPointId={parents[2]?.id}
                                 onAddDeliveryPoints={this.addDeliveryPoints}
                                 onRemoveDeliveryPoints={this.removeDeliveryPoints}/>)
        }else if(page.template==='note') {
            p = (<Note {...pageParams} />)
        }else if(page.template==='company_form') {
            p = <Company {...pageParams} />;
        }else if(page.template==='appointment_form'){
            p = <Appointment {...pageParams} />;
        }else if(page.template==='user_settings') {
            p = <UserSettings {...pageParams} login={this.state.login} company={this.state.preferences.company || {}}
                onUserSave={this.saveUser} onCompanySave={this.saveOwnCompany}/>;
        }else if(page.template==='template') {
            p = <Template {...pageParams} />
        }else if(page.template==='task') {
            p = <Task {...pageParams} />
        }else if(page.template==='year_consumption_form') {
            p = <YearConsumption {...pageParams} />
        }else if(page.template==='offer_recipient_form') {
            p = <OfferRecipient {...pageParams} />
        }else if(page.template==='process_export') {
            p = <ExportDetails {...pageParams}
                               exportData={page.exportData}
                               recipients={this.state.offerRecipients}
                               email={this.state.preferences.offer_email}
                               office={this.state.office}
            />
        }
        return p;
    }

  render() {
      if(!this.state.login) {
          return <div><LoginForm onSubmit={this.login} lastError={this.state.loginError}/><Progress progresses={this.state.progresses}/></div>;
      }
      if(!this.state.online) {
          return <Progress progresses={{1: {background: false}}}/>
      }
    let pages = [];
    let alerts = [];
    let modalActive = false;
    pages.push(...this.state.pages.map((item, idx) => {
        if(item.windowed) {
            modalActive = true;
            return <div className="modal" key={'page_' + idx}>
                <div className="modal-dialog modal-page">
                    {this.renderPage(item, true)}
                </div>
            </div>
        }else {
            modalActive = false;
            return <div className="fullscreen" key={'page_' + idx}>
                {this.renderPage(item)}
            </div>
        }
    }));
    if(this.state.modal) {
        const modalButtons = this.state.modal.buttons.map((item,idx) =>
            <SecondaryButton className="btn" key={idx} onClick={() => {
                if(item.onClick){
                    if(item.onClick()===false) {
                        return;
                    }
                }
                this.closeModal(this.state.modal.id);
                }}>{item.text}</SecondaryButton>);
        alerts.push(
            <div key={1} className="modal">
                <div className="modal-dialog">
                    <div className="modal-header">{this.state.modal.title}</div>
                    <div className="modal-body">{this.state.modal.body}</div>
                    <div className="modal-footer">{modalButtons}</div>
                </div>
            </div>
        );
        modalActive = true;
    }
    if(alerts.length>0) {
        alerts = <div>{alerts}</div>;
    }else{
        alerts = undefined;
    }
    return (
        <div className={modalActive ? 'disable-input' : ''}>
            <Header {...this.props} haveBack={this.state.pages.length>1}
                    currentPage={this.getActivePage()}
                    onBack={() => this.back()}
                    onNewClient={this.newClient}
                    onNewDeliveryPoint={this.newDeliveryPoint}
                    onNewDocument={this.newDocument}
                    onNewClientNote={this.newNote}
                    onNewClientAppointment={this.newAppointment}
                    onClientExport={this.exportClient}
                    onNewTemplate={this.newTemplate}
                    onNewTask={this.newTask}
                    onImport={this.importFile}
                    onSettings={() => this.openPage({
                        template: 'user_settings'
                    })}
                    onOnedrive={() => window.location.href = this.state.login.onedrive_login}
                    onLogout={() => this.logout('Odhlášení proběhlo úspěšně')}
                    login={this.state.login}
            />
          <div className="content">
            {pages}
          </div>
          <Progress progresses={this.state.progresses} />
          {alerts}
        </div>
    );
  }
}

export default App;
